import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { getCookie } from "@/services/utils";
import { useNavigate } from "react-router-dom";
import { Loader } from "@/components";
import SideNav from "../SideNav";
import "./AppLayout.scss";
import OrgRoleLayout from "../OrgRoleLayout";
import NoSleep from "nosleep.js";
import SessionNotesDownload from "@/components/SessionNotesDownload";

const noSleep = new NoSleep();

function AppLayout(props) {
  const navigate = useNavigate();
  useEffect(() => {
    let authToken = getCookie("authtoken");
    if (!authToken) {
      navigate("/login", { replace: true });
    } else {
      props.getUserData();
    }
  }, []);
  useEffect(() => {
    noSleep.enable();
    return () => {
      noSleep.disable();
    };
  }, []);
  // useEffect(() => {
  //   props.getSessionsByPractitionerRole();
  //   let intervalId = null;
  //   intervalId = setInterval(() => {
  //     props.getSessionsByPractitionerRole({ isBackgroundProcess: true });
  //   }, 15 * 60 * 1000);
  //   return () => {
  //     if (intervalId) {
  //       clearInterval(intervalId);
  //     }
  //   };
  // }, []);
  const selectedUserRole = props.selectedUserRole;
  return (
    <div className="app-layout">
      {!props.hideSideBar && <SideNav />}
      <div className="app-layout-outlet">
        <Loader loading={props.userLoading}>
          {selectedUserRole ? <Outlet /> : <OrgRoleLayout />}
        </Loader>
      </div>
      {/* <SessionNotesDownload
        sessionNotesPdfStatus={props.sessionNotesPdfStatus}
        preferredTimezone={props.preferredTimezone}
        selectedUserRole={props.selectedUserRole}
      /> */}
    </div>
  );
}

export default AppLayout;
