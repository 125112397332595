import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { Form, Dropdown } from "react-bootstrap";
import EHRLogo from "@/assets/ehr-logo.png";
import CalendarDaysIcon from "@/assets/calendar-days.svg";
import NotesIcon from "@/assets/tasks/notes.svg";
import CalendarIcon from "@/assets/tasks/calendar.svg";
import BillingIcon from "@/assets/tasks/billing.svg";
import AudioRecording from "@/assets/appointmentActions/audio-recording.svg";
import VideoRecording from "@/assets/appointmentActions/video-recording.svg";
import PhoneSpeaker from "@/assets/appointmentActions/phone-speaker.svg";
import StartSessionModal from "../Appointments/components/StartSessionModal";
import { roleBusinessFunctionMapping } from "@/services/dummyData";
import "./Home.scss";
import UpcomingSessions from "./components/UpcomingSessions";
import Tasks from "./components/Tasks";
import { timezones } from "@/store/modules/userModule";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";
const isMobile = window.innerWidth <= 480;

function Home(props) {
  const [startSessionModalOpen, setStartSessionModalOpen] = useState(false);
  const [startSessionModalType, setStartSessionModalType] = useState("in-person");
  const businessFunction = props.selectedUserRole?.behaviorHealthRole
    ? roleBusinessFunctionMapping(props.selectedUserRole.behaviorHealthRole)
    : "";
  useEffect(() => {
    document.title = "Home";
  }, []);
  return (
    <div className="dashboard-page">
      <div className="dashboard-welcome-container">
        <div className="dashboard-welcome-msg">Hello {props.userData?.firstName}!</div>
        <div className="org-detail-container">
          <div className="org-detail">
            {props.selectedUserRole?.organizationName} |{" "}
            {businessFunction === "Clinical Care"
              ? "Provider"
              : businessFunction === "Care Operations"
              ? "Care Coordinator"
              : props.selectedUserRole?.behaviorHealthRole}
          </div>
          {!(props.userRoles?.length === 1 && props.userRoles[0].roles?.length === 1) && (
            <div
              className="change-org-link"
              onClick={() => props.selectUserRole(null)}
            >
              Change Org/Role
            </div>
          )}
        </div>
      </div>
      {isMobile && (
        <div className="preferred-timezone-container ">
          <Form.Label className="select-timezone-label">Preferred Timezone:</Form.Label>
          <Dropdown className="select-timezone-dropdown">
            <Dropdown.Toggle>
              {props.preferredTimezone?.name || ""} | {props.preferredTimezone?.abbr}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {timezones.map(timezone => {
                return (
                  <Dropdown.Item
                    as={"button"}
                    key={timezone.name}
                    onClick={() => props.selectPreferredTimezone(timezone)}
                    className="timezone-item"
                  >
                    <div className="timezone-name">
                      {timezone.name} <span className="timezone-abbr">{timezone.abbr}</span>
                    </div>
                    <div className="timezone-offset">UTC {formatTimeOffset(timezone.offset)}</div>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}

      <div className="dashboard-widgets-list">
        {/* <div className="dashboard-left-container"> */}
        <UpcomingSessions preferredTimezone={props.preferredTimezone} />
        {/* </div> */}
        <div className="dashboard-right-container">
          <div className="dashboard-time-container">
            <div className="preferred-timezone-container">
              <Form.Label className="select-timezone-label">Preferred Timezone:</Form.Label>
              <Dropdown className="select-timezone-dropdown">
                <Dropdown.Toggle>
                  {props.preferredTimezone?.name || ""} | {props.preferredTimezone?.abbr}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {timezones.map(timezone => {
                    return (
                      <Dropdown.Item
                        as={"button"}
                        key={timezone.name}
                        onClick={() => props.selectPreferredTimezone(timezone)}
                        className="timezone-item"
                      >
                        <div className="timezone-name">
                          {timezone.name} <span className="timezone-abbr">{timezone.abbr}</span>
                        </div>
                        <div className="timezone-offset">
                          UTC {formatTimeOffset(timezone.offset)}
                        </div>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* <TodayDateTime preferredTimezone={props.preferredTimezone}/> */}
          </div>
          {props.selectedUserRole?.businessFunction === "Clinical Care" && (
            <div className="dashboard-widget">
              <div className="widget-header">
                <div className="widget-title">Start Instant Session</div>
              </div>
              <div className="widget-content">
                <div className="appointment-scheduling-actions">
                  <div className="appointment-action">
                    <div className="action-icon">
                      <img
                        src={AudioRecording}
                        alt=""
                      />
                    </div>
                    <div
                      className="action-content"
                      onClick={() => {
                        setStartSessionModalOpen(true);
                        setStartSessionModalType("in-person");
                      }}
                    >
                      <div className="action-title">In-Office Session</div>
                      <div className="action-subtitle">Start an instant in-person session</div>
                    </div>
                  </div>
                  <div
                    className="appointment-action"
                    onClick={() => {
                      setStartSessionModalOpen(true);
                      setStartSessionModalType("virtual");
                    }}
                  >
                    <div className="action-icon">
                      <img
                        src={VideoRecording}
                        alt=""
                      />
                    </div>
                    <div className="action-content">
                      <div className="action-title">Telehealth Session</div>
                      <div className="action-subtitle">Start an instant telehealth session</div>
                    </div>
                  </div>
                  {/* <div
                    className="appointment-action"
                    onClick={() => {
                      setStartSessionModalOpen(true);
                      setStartSessionModalType("external-speaker-phone");
                    }}
                  >
                    <div className="action-icon">
                      <img
                        src={PhoneSpeaker}
                        alt=""
                      />
                    </div>
                    <div className="action-content">
                      <div className="action-title">External Speaker Phone</div>
                      <div className="action-subtitle">
                        Start an instant session for your telehealth appointment
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          )}
          <Tasks />
        </div>
      </div>
      <StartSessionModal
        modalOpen={startSessionModalOpen}
        toggleModal={setStartSessionModalOpen}
        sessionType={startSessionModalType}
        practitionerRoleId={props.selectedUserRole?.id}
        getSessionsByPractitionerRole={props.getSessionsByPractitionerRole}
        filterSelectedDate={moment().tz(props.preferredTimezone?.name || "America/Los_Angeles")}
      />
    </div>
  );
}

export default Home;

export const TodayDateTime = ({ preferredTimezone, format = "dddd DD MMM YYYY, hh:mm A" }) => {
  const [currentTime, setCurrentTime] = useState(moment());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);
  return (
    <div className="current-date-time">
      {moment(currentTime)
        .tz(preferredTimezone?.name || "America/Los_Angeles")
        .format(format)}{" "}
      {preferredTimezone?.abbr || "PT"}
    </div>
  );
};

function formatTimeOffset(offset) {
  // Determine if the offset is positive or negative
  const sign = offset >= 0 ? "+" : "-";

  // Take the absolute value of the offset
  const absoluteOffset = Math.abs(offset);

  // Extract hours and minutes
  const hours = Math.floor(absoluteOffset);
  const minutes = (absoluteOffset % 1) * 60;

  // Format hours and minutes to two digits
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  // Combine into the final format
  return `${sign}${formattedHours}:${formattedMinutes}`;
}
