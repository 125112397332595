import React, { useState, useEffect } from "react";
import { Loader, PrimaryButton } from "@/components";
import "./NetworkDisconnection.scss";

function NetworkDisconnection(props) {
  const [showRefreshBtn, setShowRefershBtn] = useState(false);
  useEffect(() => {
    let timeoutId = null;
    timeoutId = setTimeout(() => {
      setShowRefershBtn(true);
    }, 5000);
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);
  return (
    <div className="session-disconnected-container">
      <div className="session-disconnected-title">Network Issue</div>
      <div className="session-disconnected-message">
        {props.networkFailed
          ? "It looks like you're having trouble connecting. Please check your connection and try again."
          : "It looks like you're having trouble connecting. Please check your connection."}
      </div>
      {!props.networkFailed ? (
        <>
          <Loader
            loading={true}
            loadingMsg={"Trying to reconnect"}
          />
          {showRefreshBtn && (
            <div className="session-disconnected-actions">
              <PrimaryButton
                onClick={() => {
                  window.location.href = window.location.href;
                }}
              >
                Refresh
              </PrimaryButton>
            </div>
          )}
        </>
      ) : (
        <div className="session-disconnected-actions">
          <PrimaryButton
            onClick={() => {
              window.location.href = window.location.href;
            }}
          >
            Refresh
          </PrimaryButton>
        </div>
      )}
    </div>
  );
}

export default NetworkDisconnection;
