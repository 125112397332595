import React, { useState, useEffect } from "react";
import html2pdf from "html2pdf.js";
import moment from "moment-timezone";

const useSoapNotesData = props => {
  const subjectiveOrder = [
    "chief_complaint",
    "appetite",
    "sleep",
    "current_medications",
    "medication_compliance",
    "side_effects",
  ];

  const objectiveOrder = [
    "orientation",
    "rapport",
    "appearance",
    "mood",
    "affect",
    "speech",
    "thought_content_and_process",
    "hallucination_type(s)",
    "hallucination_type",
    "hallucination_types",
    "insight",
    "judgment",
    "cognitive",
    "psychomotor_activity",
    "memory",
  ];

  const assessmentOrder = [
    "Current Diagnosis",
    "Current Diagnoses",
    "DSM-5 Diagnoses",
    "DSM-5 Diagnosis",
    "Suggested ICD & CPT codes",
  ];

  const planOrder = ["follow_up_plans", "homework_assignments"];

  const subjectiveData = props.data?.subjective || props.data?.Subjective || null;
  const objectiveData = props.data?.objective || props.data?.Objective || null;
  const assessmentData = props.data?.assessment || props.data?.Assessment || null;
  const planData = props.data?.plan || props.data?.Plan || null;
  const notesData = [
    {
      label: "Subjective",
      data: subjectiveData,
    },
    {
      label: "Objective",
      data: objectiveData,
    },
    {
      label: "Assessment",
      data: assessmentData,
    },
    {
      label: "Plan",
      data: planData,
    },
  ];
  const sortSections = (section1, section2, section) => {
    let order =
      section === "Subjective"
        ? subjectiveOrder
        : section === "Objective"
        ? objectiveOrder
        : section === "Assessment"
        ? assessmentOrder
        : section === "Plan"
        ? planOrder
        : [];
    const indexA = order.indexOf(section1);
    const indexB = order.indexOf(section2);
    // If both keys are found in subjectiveOrder, sort based on their position
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // If one of the keys is not found in subjectiveOrder, it should come later
    if (indexA === -1) return 1; // a is extra, so it should come after b
    if (indexB === -1) return -1; // b is extra, so a should come first

    return 0;
  };
  const createSoapNotesPdfContainer = () => {
    let sessionTime = "";
    if (props.sessionData?.startTime) {
      sessionTime = `${moment(props.sessionData?.startTime)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("MMMM DD")}, ${moment(props.sessionData?.startTime)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("h:mm a")} ${props.preferredTimezone?.abbr || "PT"} | ${
        props.sessionData?.durationInMinutes
      } mins`;
    }
    let html = `<div class="soap-notes-template pdf-soap-notes-template">
    <div class="pdf-clinic-name-container">
      <div class="pdf-clinic-name">${props?.selectedUserRole?.organizationName || ""}</div>
    </div>
    <div class="pdf-patient-details-container">
      <div class="pdf-patient-item"><label class="pdf-patient-item-label">Patient Name:</label> ${
        props.sessionData?.patientFirstName || ""
      } ${props.sessionData?.patientLastName || ""}</div>
      <div class="pdf-patient-item"><label class="pdf-patient-item-label">Provider Name:</label> Dr. ${
        props.sessionData?.practitionerFirstName || ""
      } ${props.sessionData?.practitionerLastName || ""}</div>
      <div class="pdf-patient-item"><label class="pdf-patient-item-label">Appointment Time:</label> ${sessionTime}</div>
      <div class="pdf-patient-item"><label class="pdf-patient-item-label">Appointment Type:</label> ${
        props.sessionData?.modeOfDelivery === "VIRTUAL" ? "Online" : "In-Office"
      }</div>
    </div>
      ${notesData
        .map(section => {
          if (!!section.data) {
            return `<div
              class="notes-section-container"
            >
              <div class="notes-section-label">${section.label}:</div>
              <div class="notes-section-content">
                ${Object.keys(section.data)
                  .sort((subSection1, subSection2) =>
                    sortSections(subSection1, subSection2, section.label)
                  )
                  .filter(subSection => subSection !== "homework_assignments")
                  .map(subSection => {
                    let chiefComplaintValue =
                      subSection === "chief_complaint" ? section.data[subSection].result : "";
                    if (props.data?.chiefCompliantEnhanced) {
                      chiefComplaintValue = props.data.chiefCompliantEnhanced;
                    }
                    return `<div
                        class="notes-subsection-container"
                      >
                        <div class="notes-subsection-header">
                          <div class="notes-subsection-label">
                            ${convertToTitleCase(subSection)}:
                          </div>
                        </div>
                        <div class="notes-subsection-content">
                          ${
                            section.data[subSection].type === "paragraph"
                              ? `<div
                              class="notes-subsection-result"
                            >${
                              subSection === "chief_complaint"
                                ? chiefComplaintValue
                                : section.data[subSection].result
                            }</div>`
                              : ""
                          }
                          ${
                            section.data[subSection].type === "single_line_text"
                              ? `<div
                              class="notes-subsection-result"
                            >${section.data[subSection].result}</div>`
                              : ""
                          }
                          ${
                            section.data[subSection].type === "single_choice_answer"
                              ? `<div class="notes-subsection-result-single-select-container">
                              ${section.data[subSection].values_identified
                                .concat(section.data[subSection].values_remaining)
                                .map(value => {
                                  return `
                                  <div class="notes-single-select-radio-button form-check">
                                    <input 
                                      name="${section.label}-${subSection}-${value}" 
                                      id="${section.label}-${subSection}-${value}" 
                                      type="radio" 
                                      class="form-check-input" 
                                      ${section.data[subSection].result === value ? "checked" : ""} 
                                    />
                                    <label title="" for="${
                                      section.label
                                    }-${subSection}-${value}" class="form-check-label">${value}</label>
                                  </div>
                                  `;
                                })
                                .join("")}
                            </div>`
                              : ""
                          }
                          ${
                            section.data[subSection].type === "multiple_choice_answers"
                              ? `<div class="notes-subsection-result-single-select-container">
                              ${section.data[subSection].values_identified
                                .concat(section.data[subSection].values_remaining)
                                .map(value => {
                                  return `
                                  <div class="notes-single-select-radio-button form-check">
                                    <input 
                                      name="${section.label}-${subSection}-${value}" 
                                      type="checkbox" 
                                      id="${section.label}-${subSection}-${value}" 
                                      class="form-check-input"
                                      ${
                                        section.data[subSection].result?.includes(value)
                                          ? "checked"
                                          : ""
                                      }
                                    />
                                    <label title="" for="${
                                      section.label
                                    }-${subSection}-${value}" class="form-check-label">${value}</label>
                                  </div>`;
                                })
                                .join("")}
                            </div>`
                              : ""
                          }
                          ${
                            section.data[subSection].type === "list_of_values"
                              ? `${
                                  section.data[subSection].result?.length > 0
                                    ? `<div class="notes-subsection-result-list-values">
                                  ${section.data[subSection].result
                                    .map(value => {
                                      return `<div
                                        class="notes-subsection-result-list-value-item"
                                      >
                                        ${value}
                                      </div>`;
                                    })
                                    .join("")}
                                </div>`
                                    : `<div class="notes-subsection-result-list-values">
                                  <div class="notes-subsection-result-list-value-item">
                                    ${section.data[subSection].explanation || "Not Identified"}
                                  </div>
                                </div>`
                                }`
                              : ""
                          }
                        </div>
                        ${
                          section.data[subSection].explanation
                            ? `<div class="subsection-explanation-container">
                            <div class="subsection-explanation-value">
                              <strong>Explanation:</strong> ${section.data[subSection].explanation}
                            </div>
                          </div>`
                            : ""
                        }
                      </div>`;
                  })
                  .join("")}
              </div>
            </div>`;
          }
          return "";
        })
        .join("")}
    </div>`;
    return html;
  };
  const downloadSoapNotesPdf = () => {
    // Create a temporary container for the HTML string
    const container = document.createElement("div");
    container.style = "display: none;";
    container.innerHTML = createSoapNotesPdfContainer();
    let sessionTime = "";
    if (props.sessionData?.startTime) {
      sessionTime = `${moment(props.sessionData?.startTime)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("MMM DD")}, ${moment(props.sessionData?.startTime)
        .tz(props.preferredTimezone?.name || "America/Los_Angeles")
        .format("h-mm a")}`;
    }

    // Append the container to the body (or any other element)
    document.body.appendChild(container);
    var opt = {
      margin: 0.7,
      filename: "notes.pdf",
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };
    // Convert the HTML to PDF and download
    // html2pdf().from(container).set(opt).save();
    html2pdf()
      .from(container.innerHTML)
      .set(opt)
      .toPdf()
      .get("pdf")
      .then(pdf => {
        // Remove the container from the body after PDF is generated
        document.body.removeChild(container);

        // Save the PDF
        pdf.save(`${sessionTime} ${props.sessionData?.patientFirstName}-notes.pdf`);
      });
  };
  return { notesData, sortSections, downloadSoapNotesPdf };
};

export default useSoapNotesData;

export const convertToTitleCase = str => {
  return str
    .split("_") // Split the string by underscores
    .map(
      (
        word // Map over each word
      ) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize the first letter and make the rest lowercase
    )
    .join(" "); // Join the words back with spaces
};
