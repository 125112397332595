import React, { useRef, useEffect } from "react";
import { useDyteMeeting } from "@dytesdk/react-web-core";
import {
  DyteSetupScreen,
  defaultLanguage,
  useLanguage,
  defaultConfig,
} from "@dytesdk/react-ui-kit";
import "./JoinSession.scss";
import ShareMeetingLink from "../ShareMeetingLink";
import moment from "moment-timezone";
import { MemberAvatar } from "@/components";
import { ReactSVG } from "react-svg";
import PhoneIcon from "@/assets/phone.svg";

const isMobile = window.innerWidth <= 480;

function JoinSession(props) {
  const { sessionCategory, sessionDetailsData, patientId, groupId } = props;
  const { meeting } = useDyteMeeting();
  const myLanguagePack = useLanguage({
    ...defaultLanguage,
    join: "Start Session",
  });
  // console.log(meeting);
  const waitingTimer = useRef(null);
  useEffect(() => {
    if (waitingTimer.current) {
      clearInterval(waitingTimer.current);
    }
    waitingTimer.current = setInterval(() => {
      refreshSession();
    }, 900000);
    return () => {
      if (waitingTimer.current) {
        clearInterval(waitingTimer.current);
      }
    };
  }, []);
  const refreshSession = async () => {
    if (props.meetingToken) {
      let m = await props.initMeeting({
        authToken: props.meetingToken,
        defaults: {
          audio: true,
          video: false,
        },
      });
      if (m) {
        m.recording.updateRecordings = () => {};
        // m?.recording?.on("recordingUpdate", () => {
        //   console.log("recording disabled");
        //   throw "dummyerror";
        // });
      }
    }
  };
  const patientSessionTime = `${moment(props.sessionDetailsData?.startTime)
    .tz(props.preferredTimezone?.name || "America/Los_Angeles")
    .format("MMM DD, h:mm a")} ${props.preferredTimezone?.abbr || "PT"}`;
  const patientFullName = `${props.sessionDetailsData?.patientFirstName || ""}${
    props.sessionDetailsData?.patientLastName ? " " : ""
  }${props.sessionDetailsData?.patientLastName || ""}`;
  const patientPhoneNumber = props.sessionDetailsData?.patientPhoneNumber || "";
  let dyteConfig = defaultConfig;
  if (props.sessionType === "in-person") {
    dyteConfig = {
      ...defaultConfig,
      root: {
        ...defaultConfig.root,
        ["div#setupcontrols-media"]: [
          [
            "dyte-mic-toggle",
            {
              size: "sm",
            },
          ],
        ],
      },
    };
  }
  return (
    <div className="join-session-screen">
      <div className="patient-details-container">
        <MemberAvatar
          name={patientFullName}
          size="md"
          showFullName={true}
        />
        <div className="patient-contact-item">{patientSessionTime}</div>
        {patientPhoneNumber && (
          <div className="patient-contact-item phone-number">
            <ReactSVG
              src={PhoneIcon}
              className="patient-contact-icon"
            />
            <span className="patient-contact-value">{patientPhoneNumber}</span>
          </div>
        )}
      </div>
      <DyteSetupScreen
        meeting={meeting}
        t={myLanguagePack}
        config={dyteConfig}
        size={isMobile ? "sm" : "lg"}
      />
      {props.sessionType === "virtual" && (
        <ShareMeetingLink
          sessionCategory={sessionCategory}
          sessionDetailsData={sessionDetailsData}
          patientId={patientId}
          groupId={groupId}
        />
      )}
    </div>
  );
}

export default JoinSession;
