import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip, Dropdown, Form } from "react-bootstrap";
import { PrimaryButton, SecondaryButton, MemberAvatar } from "@/components";
import { sendDelayMessage } from "@/Routes/Appointments/appointmentsModule";
import GroupsPlaceholderIcon from "@/assets/group-avatar-placeholder.svg";
import moment from "moment-timezone";
import "./WaitingStatus.scss";
import SessionCard from "../SessionCard";

function WaitingStatus(props) {
  let { session, patientStatus, groupStatus } = props;
  const [waitingData, setWaitingData] = useState(null);
  const [waitingTime, setWaitingTime] = useState(0);
  const [lateModalOpen, setLateModalOpen] = useState(false);
  const [sessionDetailModalOpen, setSessionDetailModalOpen] = useState(false);
  const patientStatusObj = patientStatus?.[session?.patientId] || null;
  const groupPatientStatusObj = groupStatus?.[session?.groupId] || null;
  let waitingTimer = null;
  useEffect(() => {
    if (session?.sessionCategory === "INDIVIDUAL") {
      if (patientStatusObj) {
        setWaitingData(patientStatusObj);
      }
    } else {
      if (groupPatientStatusObj) {
        setWaitingData(groupPatientStatusObj);
      }
    }
  }, [patientStatusObj, groupPatientStatusObj]);
  useEffect(() => {
    if (session?.sessionCategory === "INDIVIDUAL" && waitingData) {
      if (waitingTimer) {
        clearInterval(waitingTimer);
      }
      getWaitingTime();
      waitingTimer = setInterval(() => {
        getWaitingTime();
      }, 30000);
    }
    return () => {
      if (waitingTimer) {
        clearInterval(waitingTimer);
      }
    };
  }, [waitingData]);
  const patientName = `${session.patientFirstName || ""}${session.patientLastName ? " " : ""}${
    session.patientLastName || ""
  }`;
  const groupName = session.groupName;
  const getWaitingTime = () => {
    let patientJoinedTime = new Date();
    if (waitingData?.updatedAt) {
      patientJoinedTime = new Date(waitingData.updatedAt);
    }
    let secondsPassed = (new Date() - new Date(patientJoinedTime)) / 1000;
    setWaitingTime(secondsPassed);
  };
  let waitingStatus = null;
  if (session?.sessionCategory === "INDIVIDUAL") {
    if (waitingData?.status === "waitlisted") {
      waitingStatus = "Waiting Room";
      if (props.showName) {
        waitingStatus = patientName;
      }
    }
  } else {
    let totalWaitlistedPatients = 0;
    if (waitingData?.patients) {
      Object.keys(waitingData?.patients)?.forEach(patientId => {
        if (waitingData.patients[patientId].status === "waitlisted") {
          totalWaitlistedPatients++;
        }
      });
    }
    if (totalWaitlistedPatients > 0) {
      waitingStatus = `${totalWaitlistedPatients} Waiting`;
      if (props.showName) {
        waitingStatus = `${groupName} (${waitingStatus})`;
      }
    }
  }
  let waitingSinceText = "";
  let waitingSinceOverlayText = "";
  if (waitingTime < 60) {
    let time = Math.floor(waitingTime);
    waitingSinceText = `${time} s`;
    waitingSinceOverlayText = `${time} second${time > 1 ? "s" : ""}`;
  } else if (waitingTime < 60 * 60) {
    let time = Math.floor(waitingTime / 60);
    waitingSinceText = `${time} m`;
    waitingSinceOverlayText = `${time} minute${time > 1 ? "s" : ""}`;
  } else if (waitingTime < 60 * 60 * 24) {
    let time = Math.floor(waitingTime / (60 * 60));
    waitingSinceText = `${time} hr`;
    waitingSinceOverlayText = `${time} hour${time > 1 ? "s" : ""}`;
  } else {
    let time = Math.floor(waitingTime / (60 * 60 * 24));
    waitingSinceText = `${time} day`;
    waitingSinceOverlayText = `${time} day${time > 1 ? "s" : ""}`;
  }
  if (!waitingStatus) {
    return <></>;
  }
  let OverlayElem =
    session?.sessionCategory === "INDIVIDUAL" && waitingTime > 30 ? OverlayTrigger : React.Fragment;
  return (
    <>
      <OverlayElem
        overlay={<Tooltip>Waiting since {waitingSinceOverlayText}</Tooltip>}
        placement={props.overlayPlacement || "top"}
      >
        <div
          className="waiting-status"
          onClick={() =>
            props.openDetails ? setSessionDetailModalOpen(true) : setLateModalOpen(true)
          }
        >
          {waitingStatus}
          {session?.sessionCategory === "INDIVIDUAL" && waitingTime > 30 && (
            <span className={`waiting-time ${waitingTime > 60 * 5 ? "red-text" : ""}`}>
              {" "}
              {waitingSinceText}
            </span>
          )}
        </div>
      </OverlayElem>
      <RunningLateModal
        modalOpen={lateModalOpen}
        toggleModal={setLateModalOpen}
        session={session}
        preferredTimezone={props.preferredTimezone}
      />
      <SessionDetailModal
        modalOpen={sessionDetailModalOpen}
        toggleModal={setSessionDetailModalOpen}
        session={session}
        setLateModalOpen={setLateModalOpen}
        preferredTimezone={props.preferredTimezone}
      />
    </>
  );
}

export default WaitingStatus;

const SessionDetailModal = props => {
  const { session } = props;
  const onStartSession = () => {
    let meetingLink = `/session/${session.sessionCategory === "GROUP" ? "group" : "individual"}/${
      session.modeOfDelivery === "VIRTUAL" ? "virtual" : "in-person"
    }/${session.id}/${session.sessionCategory === "GROUP" ? session.groupId : session.patientId}`;
    window.location.href = window.location.origin + meetingLink;
  };
  return (
    <Modal
      show={props.modalOpen}
      onHide={() => props.toggleModal(false)}
      className="running-late-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Patient in Waiting Room</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="session-detail-modal-body">
          <SessionCard
            session={session}
            isUpcoming={true}
            hideStartSessionButton={true}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="session-detail-modal-footer">
        <SecondaryButton onClick={() => props.setLateModalOpen(true)}>
          Send Running Late Message
        </SecondaryButton>
        <PrimaryButton onClick={onStartSession}>Start Session</PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};

const RunningLateModal = props => {
  const { session } = props;
  const [message, setMessage] = useState("");
  const [lateMinutes, setLateMinutes] = useState(0);
  const [loading, setLoading] = useState(false);
  const patientId = session?.patientId || "";
  const groupId = session?.groupId || "";
  const providerName = `${session?.practitionerFirstName || ""}${
    session?.practitionerLastName ? " " : ""
  }${session?.practitionerLastName || ""}`;
  const patientFullName = `${session?.patientFirstName || ""}${
    session?.patientLastName ? " " : ""
  }${session?.patientLastName || ""}`;
  const groupName = session?.groupName || "";
  const patientSessionTime = `${moment(session?.startTime)
    .tz(props.preferredTimezone?.name || "America/Los_Angeles")
    .format("h:mm a")} ${props.preferredTimezone?.abbr || "PT"} | ${
    session?.durationInMinutes
  } mins`;
  const patientPhoneNumber = session?.patientPhoneNumber || "";
  const isGroupSession = session?.sessionCategory !== "INDIVIDUAL";
  useEffect(() => {
    if (props.modalOpen) {
      const sessionStartTime = session?.startTime ? new Date(session.startTime) : null;
      let lateMinutes = 0;
      let message = "";
      if (sessionStartTime) {
        let delaySeconds = (new Date() - sessionStartTime) / 1000;
        if (delaySeconds <= 0) {
          lateMinutes = 5;
        } else {
          lateMinutes = Math.ceil(delaySeconds / (60 * 5)) * 5;
          if (lateMinutes > 30) {
            lateMinutes = 30;
          }
        }
      } else {
        lateMinutes = 0;
      }
      message = `Dr. ${providerName} is running late by approximately ${lateMinutes} minutes & will be with you shortly. Thank you for your patience.`;
      setLateMinutes(lateMinutes);
      setMessage(message);
    } else {
      setMessage("");
      setLateMinutes(0);
      setLoading(false);
    }
  }, [props.modalOpen]);
  const selectLateMinutes = minutes => {
    setLateMinutes(minutes);
    const message = `Dr. ${providerName} is running late by approximately ${minutes} minutes & will be with you shortly. Thank you for your patience.`;
    setMessage(message);
  };
  const sendMessage = async () => {
    setLoading(true);
    let options = {
      message,
      patientPhoneNumber,
      isGroupSession,
      groupId,
      patientId,
    };
    await sendDelayMessage(options);
    props.toggleModal(false);
    setLoading(false);
  };
  return (
    <Modal
      show={props.modalOpen}
      onHide={() => props.toggleModal(false)}
      className="running-late-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Running Late? Let your patient{isGroupSession ? "s" : ""} know</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="patient-container">
          <MemberAvatar
            name={isGroupSession ? groupName : patientFullName}
            size="md"
            showFullName={true}
            placeholderImg={isGroupSession ? GroupsPlaceholderIcon : null}
          />
          <div className="patient-session-time">{patientSessionTime}</div>
        </div>
        <div className="late-minutes-container">
          <Form.Label className="late-minutes-label">Running late by:</Form.Label>
          <Dropdown className="late-minutes-dropdown">
            <Dropdown.Toggle>{lateMinutes} minutes</Dropdown.Toggle>
            <Dropdown.Menu>
              {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60].map(minutes => {
                return (
                  <Dropdown.Item
                    as={"button"}
                    onClick={() => selectLateMinutes(minutes)}
                    key={minutes}
                  >
                    {minutes}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="message-container">
          <Form.Label className="message-label">
            Let the patient{isGroupSession ? "s" : ""} know that you are running late:
          </Form.Label>
          <textarea
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <SecondaryButton onClick={() => props.toggleModal(false)}>Cancel</SecondaryButton>
        <PrimaryButton
          onClick={sendMessage}
          disabled={!message || loading}
        >
          {!loading ? "Send Message" : "Sending"}
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};
