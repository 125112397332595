import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "@/components";
import PatientNotes from "./components/PatientNotes";
import Transcript from "./components/Transcript";
import TranscriptLoader from "./components/TranscriptLoader";
import { ReactSVG } from "react-svg";
import NotesIcon from "@/assets/tasks/notes.svg";
import "./SessionDetails.scss";

import SpeakerMapping from "./components/SpeakerMapping";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function SessionDetails(props) {
  const [hideTranscript, setHideTranscript] = useState(false);
  const [speakerMappingOpen, setSpeakerMappingOpen] = useState(false);
  const { sessionCategory, sessionType, sessionId, patientId } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const inPersonOldSession = urlParams.get("inPersonOldSession");
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (props.session?.data && !props.session.loading && props.session?.data?.id) {
      let patientName =
        props.session?.data?.sessionCategory === "INDIVIDUAL"
          ? `${props.session?.data?.patientFirstName || ""} ${
              props.session?.data?.patientLastName || ""
            }`
          : props.session?.data?.groupName;
      document.title = `Notes | ${patientName}`;
    } else {
      document.title = "Notes";
    }
  }, [props.session?.data, patientId]);
  const getData = async () => {
    let sessionDetails = await props.getSessionDetails({ sessionId, sessionCategory });
    let retryTranscript = false;
    if (sessionDetails) {
      const startTime = new Date(sessionDetails?.startTime);
      const endTime = new Date(sessionDetails?.endTime);
      const currentTime = new Date();
      const endTimePlus60 = new Date(endTime.getTime() + 2 * 60 * 60000);
      // retryTranscript = currentTime > startTime && currentTime < endTimePlus60;
      retryTranscript = currentTime < endTimePlus60;
    }
    if (sessionType === "virtual") {
      props.getProviderSessionIds({
        sessionCategory,
        sessionId,
        triggerTime: new Date(),
      });
    } else {
      // Shifted to dyte for in person sessions from 9th September 2024
      if (!inPersonOldSession) {
        props.getProviderSessionIds({
          sessionCategory,
          sessionId,
          triggerTime: new Date(),
        });
      } else {
        props.getSessionTranscript({
          sessionId,
          sessionType,
          sessionCategory,
          retryTranscript,
          triggerTime: new Date(),
        });
      }
    }

    // props.getMemberNotes({ sessionId, patientId });
    props.getSessionMemberNotes({ sessionId, patientId });
  };
  return (
    <TranscriptLoader
      loading={props.sessionTranscript.loading || props.session.loading}
      loadingMsg={"Please wait while we fetch the session transcript"}
    >
      <div className={`session-details-container`}>
        {(!!props.sessionTranscript.speakerMapped ||
          sessionCategory !== "group" ||
          props.sessionTranscript?.data?.length === 0) &&
          !speakerMappingOpen && (
            <div className={`session-details-wrapper ${hideTranscript ? "hide-transcript" : ""}`}>
              <PatientNotes
                memberNotes={props.memberNotes}
                soapNotes={props.soapNotes}
                narrative={props.narrative}
                updateMemberNotes={props.updateMemberNotes}
                generateSoapNotes={props.generateSoapNotes}
                saveSoapNotes={props.saveSoapNotes}
                sessionId={sessionId}
                groupId={sessionCategory === "group" ? patientId : null}
                patientId={sessionCategory === "individual" ? patientId : null}
                sessionType={sessionType}
                sessionCategory={sessionCategory}
                transcriptData={props.sessionTranscript.data}
                bhPredictions={props.bhPredictions}
                generateBhPredictions={props.generateBhPredictions}
                saveBhPredictions={props.saveBhPredictions}
                hideTranscript={hideTranscript}
                toggleTranscript={setHideTranscript}
                sessionData={props.session.data}
                removeTask={props.removeTask}
                preferredTimezone={props.preferredTimezone}
                treatmentPlan={props.treatmentPlan}
                generateTreatmentPlan={props.generateTreatmentPlan}
                sessionTranscript={props.sessionTranscript}
                generateNarrative={props.generateNarrative}
                saveNarrativeData={props.saveNarrativeData}
                selectedUserRole={props.selectedUserRole}
              />
              {!hideTranscript ? (
                <div className="session-transcript-wrapper">
                  <OverlayTrigger
                    overlay={<Tooltip>Hide Transcript</Tooltip>}
                    placement="bottom"
                  >
                    <div
                      className="collapse-btn"
                      onClick={() => setHideTranscript(true)}
                    >
                      <div className="collapse-icon"></div>
                    </div>
                  </OverlayTrigger>
                  <Transcript
                    {...props.sessionTranscript}
                    sessionCategory={sessionCategory}
                    sessionData={props.session.data}
                    setSpeakerMappingOpen={setSpeakerMappingOpen}
                    selectedUserRole={props.selectedUserRole}
                  />
                </div>
              ) : (
                <div className="transcript-collapsed-container">
                  <div
                    className="transcript-collapsed-action"
                    onClick={() => setHideTranscript(false)}
                  >
                    <ReactSVG
                      src={NotesIcon}
                      className="transcript-collapsed-action-icon"
                    />
                    <div className="transcript-collapsed-action-label">Transcript</div>
                  </div>
                </div>
              )}
            </div>
          )}
        {((!props.sessionTranscript.speakerMapped &&
          sessionCategory === "group" &&
          props.sessionTranscript?.data?.length > 0) ||
          !!speakerMappingOpen) && (
          <SpeakerMapping
            sessionId={sessionId}
            transcriptData={props.sessionTranscript.data}
            csvData={props.sessionTranscript.csvData}
            providerSessionTranscriptsData={props.sessionTranscript.providerSessionTranscriptsData}
            saveSpeakerMapping={props.saveSpeakerMapping}
            sessionData={props.session.data}
            groupId={patientId}
            sessionCategory={sessionCategory}
            setSpeakerMappingOpen={setSpeakerMappingOpen}
          />
        )}
      </div>
    </TranscriptLoader>
  );
}

export default SessionDetails;
