import React, { useEffect } from "react";
import { Provider, useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LogRocket from "logrocket";
import { ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import store from "./store";

import AppLayout from "./Layout/AppLayout";
import AuthLayout from "./Layout/AuthLayout";
import ErrorLayout from "./Layout/ErrorLayout";
import Login from "./Routes/Login";
import Signup from "./Routes/Signup";
import ResetPassword from "./Routes/ResetPassword";
import Profile from "./Routes/Profile";
import Session from "./Routes/Session";
import SessionDetails from "./Routes/SessionDetails";
import PatientDetails from "./Routes/PatientDetails";
import Appointments from "./Routes/Appointments";
import Patients from "./Routes/Patients";
import Users from "./Routes/Users";

import Home from "./Routes/Home";
import {
  completedSessionsSeedData,
  patientsData,
  businessFunctions,
  roleBusinessFunctionMapping,
} from "./services/dummyData";
import { getLocalStorage, addLocalStorage } from "./services/utils";
import Groups from "./Routes/Groups";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

const getCompletedSessions = () => {
  let completedSessions = getLocalStorage("completedSessions") || [];
  let completedSessionsIds = completedSessions.map(i => i.sessionId);
  let completedSessionsSeedDataList = completedSessionsSeedData.filter(
    i => !completedSessionsIds.includes(i.sessionId)
  );
  completedSessions = [...completedSessions, ...completedSessionsSeedDataList];
  completedSessions = completedSessions.map(session => {
    return {
      ...session,
      patientData: patientsData.find(i => i.patient_id === session.patientId) || null,
    };
  });
  completedSessions = completedSessions.sort(
    (session1, session2) => new Date(session2.sessionDate) - new Date(session1.sessionDate)
  );
  addLocalStorage("completedSessions", completedSessions);
};

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      if (APP_ENV === "PROD") {
        LogRocket.init("kgns4k/facilitator-dashboard-prod", { release: "ehr-prod" });
      } else {
        LogRocket.init("kgns4k/facilitator-dashboard-prod", {
          release: "ehr-dev",
        });
      }
    }
  }, []);
  return (
    <Provider store={store}>
      <AppRouter />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </Provider>
  );
}

const AppRouter = props => {
  const { selectedUserRole } = useSelector(state => state.user);
  let businessFunction = selectedUserRole?.businessFunction;
  let defaultRoutingMapping = [
    {
      index: true,
      element: <Appointments />,
    },
    {
      path: "/profile",
      element: <Profile />,
    },
  ];
  let appRoutingMapping = {
    ["Clinical Care"]: [
      {
        index: true,
        element: <Appointments />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/session/:sessionCategory/:sessionType/:sessionId/:patientId",
        element: <Session />,
      },
      {
        path: "/session-details/:sessionCategory/:sessionType/:sessionId/:patientId",
        element: <SessionDetails />,
      },
      { path: "patients", element: <Patients /> },
      {
        path: "/patients/:patientId",
        element: <PatientDetails />,
      },
      {
        path: "/appointments",
        element: <Appointments />,
      },
      {
        path: "/groups",
        element: <Groups />,
      },
    ],
    ["Care Operations"]: [
      {
        index: true,
        element: <Appointments />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/session-details/:sessionCategory/:sessionType/:sessionId/:patientId",
        element: <SessionDetails />,
      },
      { path: "patients", element: <Patients /> },
      {
        path: "/patients/:patientId",
        element: <PatientDetails />,
      },
      {
        path: "/appointments",
        element: <Appointments />,
      },
      {
        path: "/groups",
        element: <Groups />,
      },
    ],
    ["Management and Leadership"]: [
      {
        index: true,
        element: <Appointments />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/session/:sessionCategory/:sessionType/:sessionId/:patientId",
        element: <Session />,
      },
      {
        path: "/session-details/:sessionCategory/:sessionType/:sessionId/:patientId",
        element: <SessionDetails />,
      },
      { path: "patients", element: <Patients /> },
      {
        path: "/patients/:patientId",
        element: <PatientDetails />,
      },
      {
        path: "/appointments",
        element: <Appointments />,
      },
      { path: "users", element: <Users /> },
      {
        path: "/groups",
        element: <Groups />,
      },
    ],
  };
  const router = createBrowserRouter([
    {
      path: "/",
      errorElement: !businessFunction ? <AppLayout /> : <ErrorLayout />,
      children: [
        {
          element: <AppLayout />,
          children: appRoutingMapping[businessFunction] || defaultRoutingMapping,
        },
        {
          element: <AuthLayout />,
          children: [
            {
              path: "/login",
              element: <Login />,
            },
            {
              path: "/practitioner/accept-invite-and-signup",
              element: <Signup />,
            },
            {
              path: "/reset-password",
              element: <ResetPassword />,
            },
          ],
        },
      ],
    },
  ]);
  return (
    <RouterProvider router={router}>
      <div>App</div>
    </RouterProvider>
  );
};

export default App;
