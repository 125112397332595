import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "@/components";
import SessionAppointments from "@/Routes/Session/components/SessionAppointments";
import "./SessionEnded.scss";

function SessionEnded(props) {
  const navigate = useNavigate();
  const { sessionCategory, patientId, sessionId } = useParams();
  const joinRoom = m => {
    try {
      m.join();
    } catch (error) {
      console.log("error", error);
      setTimeout(() => {
        joinRoom(m);
      }, 5000);
    }
  };
  const rejoinSession = async () => {
    // await meeting.join();
    // if (props.meetingToken) {
    //   let m = await props.initMeeting({
    //     authToken: props.meetingToken,
    //     defaults: {
    //       audio: true,
    //       video: false,
    //     },
    //   });
    //   if (m) {
    //     // m?.recording?.on("recordingUpdate", () => {
    //     //   console.log("recording disabled");
    //     //   throw "dummyerror";
    //     // });
    //     m.recording.updateRecordings = () => {};
    //     // await m.join();
    //     console.log("join room");
    //     try {
    //       joinRoom(m);
    //     } catch (error) {
    //       console.log("error", error);
    //     }
    //   }
    // }
    window.location.href = window.location.href;
  };
  const reviewNotes = () => {
    navigate(`/session-details/${sessionCategory}/${props.sessionType}/${sessionId}/${patientId}`, {
      replace: true,
    });
  };
  return (
    <div className="session-ended-container">
      <div className="session-ended-message">
        Your session with
        {props.sessionDetailsData?.sessionCategory === "GROUP"
          ? ` ${props.sessionDetailsData?.groupName || ""}`
          : ` ${props.sessionDetailsData?.patientFirstName || ""}${
              props.sessionDetailsData?.patientLastName ? " " : ""
            }${props.sessionDetailsData?.patientLastName || ""}`}{" "}
        has ended.
      </div>
      <div className="session-ended-message">
        Recording complete! Your notes and transcript will be generated shortly.
      </div>
      {/* <div className="session-ended-message">Review notes for this session</div> */}
      <div className="session-ended-actions">
        <SecondaryButton onClick={() => rejoinSession()}>Restart Session</SecondaryButton>
        <PrimaryButton onClick={() => reviewNotes()}>Review Notes Now</PrimaryButton>
      </div>
      <div className="separator">Or</div>
      <div className="start-next-appointment-msg">Start your next appointment</div>
      <div className="your-appointments-container">
        <SessionAppointments
          endSession={() => console.log("end session")}
          sessionEnded={true}
          currentSessionStatus={"Ended"}
        />
      </div>
    </div>
  );
}

export default SessionEnded;
