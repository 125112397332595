import React, { useState, useEffect } from "react";
import InfoIcon from "@/assets/info.svg";
import CopyIcon from "@/assets/copy.svg";
import "./SoapNotesTemplate.scss";
import { OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import html2pdf from "html2pdf.js";
import moment from "moment-timezone";
import DeleteIcon from "@/assets/delete-icon.svg";
import useSoapNotesData, { convertToTitleCase } from "./useSoapNotesData";

function copyTextToClipboard(text) {
  // Create a temporary textarea element
  const textarea = document.createElement("textarea");

  // Set the textarea value to the text you want to copy
  textarea.value = text;

  // Add the textarea to the document
  document.body.appendChild(textarea);

  // Select the text in the textarea
  textarea.select();

  // Copy the selected text to the clipboard
  try {
    document.execCommand("copy");
    console.log("Text copied to clipboard");
  } catch (err) {
    console.error("Failed to copy text", err);
  }

  // Remove the textarea from the document
  document.body.removeChild(textarea);
}

const listOfValuesNewItemMapping = {
  current_medications: "Medication",
  "Current Diagnosis": "Diagnosis",
  "Suggested ICD & CPT codes": "Code",
  follow_up_plans: "Plan",
};

function SoapNotesTemplate(props) {
  const [textCopiedSection, setTextCopiedSection] = useState("");
  const { notesData, sortSections, downloadSoapNotesPdf } = useSoapNotesData({
    data: props.data,
    sessionData: props.sessionData,
    preferredTimezone: props.preferredTimezone,
    selectedUserRole: props.selectedUserRole,
  });
  useEffect(() => {
    setTextCopiedSection("");
  }, []);
  let copyTimer = null;
  const copyText = (section, subSection, text) => {
    copyTextToClipboard(text);
    setTextCopiedSection(`${section}-${subSection}`);
    if (copyTimer) {
      clearTimeout(copyTimer);
    }
    copyTimer = setTimeout(() => {
      setTextCopiedSection("");
    }, 3000);
  };
  const changeResultValue = (section, subSection, value) => {
    let newData = { ...props.data };
    newData = {
      ...newData,
      [section]: {
        ...newData[section],
        [subSection]: {
          ...newData[section][subSection],
          result: value,
        },
      },
    };
    props.changeSoapJsonData(newData);
  };
  const changeEnhancedChiefComplaintValue = value => {
    let newData = { ...props.data };
    newData = {
      ...newData,
      ["chiefCompliantEnhanced"]: value,
    };
    props.changeSoapJsonData(newData);
  };
  const changeMultiSelectResultValue = (section, subSection, value, add) => {
    let newData = { ...props.data };
    newData = {
      ...newData,
      [section]: {
        ...newData[section],
        [subSection]: {
          ...newData[section][subSection],
          result: !!add
            ? [...newData[section][subSection].result, value]
            : newData[section][subSection].result.filter(i => i !== value),
        },
      },
    };
    props.changeSoapJsonData(newData);
  };
  const addNewListItem = (section, subSection) => {
    let newData = { ...props.data };
    newData = {
      ...newData,
      [section]: {
        ...newData[section],
        [subSection]: {
          ...newData[section][subSection],
          result: [...newData[section][subSection].result, ""],
        },
      },
    };
    props.changeSoapJsonData(newData);
  };
  const deleteListItem = (section, subSection, index) => {
    let newData = { ...props.data };
    newData = {
      ...newData,
      [section]: {
        ...newData[section],
        [subSection]: {
          ...newData[section][subSection],
          result: newData[section][subSection].result
            .slice(0, index)
            .concat(
              newData[section][subSection].result.slice(
                index + 1,
                newData[section][subSection].result.length
              )
            ),
        },
      },
    };
    props.changeSoapJsonData(newData);
  };
  const changeListItemValue = (section, subSection, index, value) => {
    let newData = { ...props.data };
    newData = {
      ...newData,
      [section]: {
        ...newData[section],
        [subSection]: {
          ...newData[section][subSection],
          result: newData[section][subSection].result.map((i, listIndex) => {
            if (listIndex === index) {
              return value;
            }
            return i;
          }),
        },
      },
    };
    props.changeSoapJsonData(newData);
  };

  return (
    <div className="soap-notes-template">
      <div
        onClick={downloadSoapNotesPdf}
        className="download-pdf-btn"
      >
        Download PDF
      </div>
      {notesData.map(section => {
        if (!!section.data) {
          return (
            <div
              className="notes-section-container"
              key={section.label}
            >
              <div className="notes-section-label">{section.label}:</div>
              <div className="notes-section-content">
                {Object.keys(section.data)
                  .sort((subSection1, subSection2) =>
                    sortSections(subSection1, subSection2, section.label)
                  )
                  .filter(subSection => subSection !== "homework_assignments")
                  .map((subSection, index) => {
                    let chiefComplaintValue =
                      subSection === "chief_complaint" ? section.data[subSection].result : "";
                    let onChiefComplaintChange = e => {
                      changeResultValue(section.label.toLowerCase(), subSection, e?.target?.value);
                    };
                    if (props.data?.chiefCompliantEnhanced) {
                      chiefComplaintValue = props.data.chiefCompliantEnhanced;
                      onChiefComplaintChange = e => {
                        changeEnhancedChiefComplaintValue(e?.target?.value);
                      };
                    }
                    if (notesData)
                      return (
                        <div
                          className="notes-subsection-container"
                          key={index}
                        >
                          <div className="notes-subsection-header">
                            <div className="notes-subsection-label">
                              {convertToTitleCase(subSection)}:{" "}
                            </div>
                            {(section.data[subSection].type === "paragraph" ||
                              section.data[subSection].type === "single_line_text") && (
                              <div
                                className="copy-container"
                                onClick={() =>
                                  copyText(
                                    "subjective",
                                    subSection,
                                    subSection === "chief_complaint"
                                      ? chiefComplaintValue || ""
                                      : section.data[subSection].result || ""
                                  )
                                }
                              >
                                <img
                                  src={CopyIcon}
                                  alt=""
                                  className="copy-icon"
                                />
                                <span>
                                  {textCopiedSection === `subjective-${subSection}`
                                    ? "Copied"
                                    : "Copy"}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="notes-subsection-content">
                            {section.data[subSection].type === "paragraph" && (
                              <textarea
                                className="notes-subsection-result"
                                onChange={
                                  subSection === "chief_complaint"
                                    ? onChiefComplaintChange
                                    : e =>
                                        changeResultValue(
                                          section.label.toLowerCase(),
                                          subSection,
                                          e?.target?.value
                                        )
                                }
                                value={
                                  subSection === "chief_complaint"
                                    ? chiefComplaintValue
                                    : section.data[subSection].result
                                }
                              />
                            )}
                            {section.data[subSection].type === "single_line_text" && (
                              <input
                                className="notes-subsection-result"
                                onChange={e =>
                                  changeResultValue(
                                    section.label.toLowerCase(),
                                    subSection,
                                    e?.target?.value
                                  )
                                }
                                value={section.data[subSection].result}
                              />
                            )}
                            {section.data[subSection].type === "single_choice_answer" && (
                              <div className="notes-subsection-result-single-select-container">
                                {section.data[subSection].values_identified
                                  .concat(section.data[subSection].values_remaining)
                                  .map(value => {
                                    return (
                                      <Form.Check
                                        type="radio"
                                        label={value}
                                        key={value}
                                        name={`${section.label}-${subSection}-${value}`}
                                        id={`${section.label}-${subSection}-${value}`}
                                        checked={section.data[subSection].result === value}
                                        onChange={() =>
                                          changeResultValue(
                                            section.label.toLowerCase(),
                                            subSection,
                                            value
                                          )
                                        }
                                        className="notes-single-select-radio-button"
                                      />
                                    );
                                  })}
                              </div>
                            )}
                            {section.data[subSection].type === "multiple_choice_answers" && (
                              <div className="notes-subsection-result-single-select-container">
                                {section.data[subSection].values_identified
                                  .concat(section.data[subSection].values_remaining)
                                  .map(value => {
                                    return (
                                      <Form.Check
                                        type="checkbox"
                                        label={value}
                                        key={value}
                                        name={`${section.label}-${subSection}-${value}`}
                                        id={`${section.label}-${subSection}-${value}`}
                                        checked={section.data[subSection].result?.includes(value)}
                                        onChange={() =>
                                          changeMultiSelectResultValue(
                                            section.label.toLowerCase(),
                                            subSection,
                                            value,
                                            !section.data[subSection].result?.includes(value)
                                          )
                                        }
                                        className="notes-single-select-radio-button"
                                      />
                                    );
                                  })}
                              </div>
                            )}
                            {section.data[subSection].type === "list_of_values" && (
                              <>
                                {section.data[subSection].result?.length > 0 ? (
                                  <>
                                    <div className="notes-subsection-result-list-values">
                                      {section.data[subSection].result.map((value, index) => {
                                        return (
                                          <div
                                            className="notes-subsection-result-list-value-item-container"
                                            key={index}
                                          >
                                            <div className="notes-subsection-result-list-value-item">
                                              {subSection === "current_medications" ? (
                                                <input
                                                  value={value}
                                                  type="text"
                                                  onChange={e =>
                                                    changeListItemValue(
                                                      section.label.toLowerCase(),
                                                      subSection,
                                                      index,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <span>{value}</span>
                                              )}
                                            </div>
                                            {subSection === "current_medications" && (
                                              <OverlayTrigger
                                                overlay={
                                                  <Tooltip>
                                                    Delete{" "}
                                                    {listOfValuesNewItemMapping[subSection] || ""}
                                                  </Tooltip>
                                                }
                                                placement="top"
                                              >
                                                <div
                                                  className="delete-icon"
                                                  onClick={() =>
                                                    deleteListItem(
                                                      section.label.toLowerCase(),
                                                      subSection,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={DeleteIcon}
                                                    alt=""
                                                  />
                                                </div>
                                              </OverlayTrigger>
                                            )}
                                          </div>
                                        );
                                      })}
                                    </div>
                                    {subSection === "current_medications" && (
                                      <div
                                        className="add-new-item-list-of-values"
                                        onClick={() =>
                                          addNewListItem(section.label.toLowerCase(), subSection)
                                        }
                                      >
                                        + Add {listOfValuesNewItemMapping[subSection] || ""}
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <div className="notes-subsection-result-list-values">
                                    <div className="notes-subsection-result-list-value-item">
                                      {section.data[subSection].explanation || "Not Identified"}
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          {section.data[subSection].explanation && (
                            <div className="subsection-explanation-container">
                              <div className="subsection-explanation-value">
                                <strong>Explanation:</strong> {section.data[subSection].explanation}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                  })}
              </div>
            </div>
          );
        }
        return <React.Fragment key={section.label}></React.Fragment>;
      })}
    </div>
  );
}

export default SoapNotesTemplate;
