import React, { useState, useMemo, useEffect } from "react";
import { MemberAvatar, PrimaryButton, SecondaryButton } from "@/components";
import { Form, Dropdown } from "react-bootstrap";
import GroupsPlaceholderIcon from "@/assets/group-avatar-placeholder.svg";
import "./SpeakerMapping.scss";

function SpeakerMapping(props) {
  const [speakerMappedData, setSpeakerMappedData] = useState({});
  const [providerData, setProviderData] = useState({});
  const [unknownData, setUnknownData] = useState({});
  let transcriptData = useMemo(() => {
    let data = {};
    if (props.providerSessionTranscriptsData?.length > 0) {
      props.providerSessionTranscriptsData.forEach(providerSession => {
        if (!providerSession.speakerMapped || props.sessionCategory === "individual") {
          if (providerSession?.transcriptData?.length > 0) {
            providerSession.transcriptData.forEach(transcript => {
              data = {
                ...data,
                [transcript.memberId]: data[transcript.memberId]
                  ? {
                      ...data[transcript.memberId],
                      transcripts:
                        data[transcript.memberId].transcripts?.length > 0
                          ? [...data[transcript.memberId].transcripts, transcript]
                          : [transcript],
                      mappedSpeaker: speakerMappedData[transcript.memberId] || null,
                    }
                  : {
                      transcripts: [transcript],
                      mappedSpeaker: speakerMappedData[transcript.memberId] || null,
                    },
              };
            });
          }
        }
      });
    }
    return data;
  }, [props.providerSessionTranscriptsData]);

  useEffect(() => {
    if (props.sessionCategory === "individual") {
      let data = {};
      if (props.providerSessionTranscriptsData?.length > 0) {
        props.providerSessionTranscriptsData.forEach(providerSession => {
          // if (!providerSession.speakerMapped) {
          if (providerSession?.transcriptData?.length > 0) {
            providerSession.transcriptData.forEach(transcript => {
              data = {
                ...data,
                [transcript.memberId]: transcript.memberName,
              };
            });
          }
          // }
        });
      }
      setSpeakerMappedData(data);
    }
  }, [props.providerSessionTranscriptsData]);

  // if (props.transcriptData?.length > 0) {
  //   props.transcriptData.forEach(transcript => {
  //     transcriptData = {
  //       ...transcriptData,
  //       [transcript.memberId]: transcriptData[transcript.memberId]
  //         ? {
  //             ...transcriptData[transcript.memberId],
  //             transcripts:
  //               transcriptData[transcript.memberId].transcripts?.length > 0
  //                 ? [...transcriptData[transcript.memberId].transcripts, transcript.transcriptText]
  //                 : [transcript.transcriptText],
  //             mappedSpeaker: speakerMappedData[transcript.memberId] || null,
  //           }
  //         : {
  //             transcripts: [transcript.transcriptText],
  //             mappedSpeaker: speakerMappedData[transcript.memberId] || null,
  //           },
  //     };
  //   });
  // }
  const mapSpeaker = (memberId, memberName) => {
    setSpeakerMappedData(prevData => {
      let newData = { ...prevData };
      if (!memberName && newData[memberId]) {
        delete newData[memberId];
      } else if (memberName) {
        newData = {
          ...newData,
          [memberId]: memberName,
        };
      }
      return newData;
    });
  };
  const markAsProvider = (memberId, flag) => {
    setProviderData(prevData => {
      let newData = { ...prevData };
      if (!!speakerMappedData[memberId]) {
        mapSpeaker(memberId);
      }
      if (!flag && newData[memberId]) {
        delete newData[memberId];
      } else if (flag) {
        newData = {
          ...newData,
          [memberId]: "Provider",
        };
      }
      let totalProviders = Object.keys(newData).length;
      let finalData = {};
      Object.keys(newData).forEach((provider, index) => {
        finalData[provider] = totalProviders > 1 ? `Provider ${index + 1}` : "Provider";
      });
      return finalData;
    });
  };
  const markAsUnknown = (memberId, flag) => {
    setUnknownData(prevData => {
      let newData = { ...prevData };
      if (!!speakerMappedData[memberId]) {
        mapSpeaker(memberId);
      }
      if (!flag && newData[memberId]) {
        delete newData[memberId];
      } else if (flag) {
        newData = {
          ...newData,
          [memberId]: "Unknown",
        };
      }
      return newData;
    });
  };
  const saveMapping = () => {
    if (
      Object.keys(speakerMappedData).length + Object.keys(providerData).length ===
      Object.keys(transcriptData).length
    ) {
      let newCsvData = [];
      let newTranscriptData = [];
      let newProviderSessionTranscriptsData = props.providerSessionTranscriptsData?.map(
        providerSession => {
          if (!!providerSession.speakerMapped && props.sessionCategory !== "individual") {
            newCsvData = [...newCsvData, ...providerSession.csvData];
            newTranscriptData = [...newTranscriptData, ...providerSession.transcriptData];
            return providerSession;
          }
          let newProviderCsvData = providerSession.csvData.map(row => {
            let newRow = [...row];
            newRow[4] = speakerMappedData[row[3]] || providerData[row[3]];
            return newRow;
          });
          let newProviderTranscriptData = providerSession.transcriptData.map(transcript => {
            return {
              ...transcript,
              memberName:
                speakerMappedData[transcript.memberId] || providerData[transcript.memberId],
            };
          });
          newCsvData = [...newCsvData, ...newProviderCsvData];
          newTranscriptData = [...newTranscriptData, ...newProviderTranscriptData];
          return {
            ...providerSession,
            csvData: newCsvData,
            transcriptData: newTranscriptData,
          };
        }
      );
      // let newCsvData = props.csvData.map(row => {
      //   let newRow = [...row];
      //   newRow[4] = speakerMappedData[row[3]] || providerData[row[3]];
      //   return newRow;
      // });
      // let newTranscriptData = props.transcriptData.map(transcript => {
      //   return {
      //     ...transcript,
      //     memberName: speakerMappedData[transcript.memberId] || providerData[transcript.memberId],
      //   };
      // });
      props.saveSpeakerMapping({
        csvData: newCsvData,
        transcriptData: newTranscriptData,
        providerSessionTranscriptsData: newProviderSessionTranscriptsData,
        sessionId: props.sessionId,
        groupId: props.groupId,
        sessionCategory: props.sessionCategory,
      });
      props.setSpeakerMappingOpen(false);
    }
  };
  return (
    <div className="speaker-mapping-container">
      <div className="speaker-mapping-patient-info-container">
        <MemberAvatar
          name={props.sessionData?.groupName}
          size={"md"}
          showFullName={true}
          placeholderImg={GroupsPlaceholderIcon}
        />
        <div className="action-btns">
          {props.sessionCategory === "individual" && (
            <SecondaryButton onClick={() => props.setSpeakerMappingOpen(false)}>
              Cancel
            </SecondaryButton>
          )}

          <PrimaryButton
            disabled={
              Object.keys(speakerMappedData).length + Object.keys(providerData).length !==
              Object.keys(transcriptData).length
            }
            onClick={saveMapping}
          >
            Save Mapping
          </PrimaryButton>
        </div>
      </div>
      <div className="speaker-mapping-desc">
        Map the speakers from the session to the patients to generate the notes
      </div>
      <div className="speaker-mapping-list">
        {Object.keys(transcriptData).map(speaker => {
          return (
            <div
              className="speaker-mapping-item"
              key={speaker}
            >
              <div className="speaker-mapping-item-patient-container">
                <div className="speaker-label">{speaker}</div>
                {props.sessionCategory === "group" && (
                  <div>
                    <div className="speaker-patient-mapping-container">
                      <Form.Label className="speaker-patient-label">Select Patient:</Form.Label>
                      <Dropdown className="speaker-patient-dropdown">
                        <Dropdown.Toggle disabled={!!providerData[speaker]}>
                          {!!speakerMappedData[speaker]
                            ? speakerMappedData[speaker]
                            : "Select Patient"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {props.sessionData?.sessionPatientMemberDtos?.length > 0 &&
                            props.sessionData.sessionPatientMemberDtos.map(patient => {
                              return (
                                <Dropdown.Item
                                  as={"button"}
                                  onClick={() =>
                                    mapSpeaker(
                                      speaker,
                                      `${patient.patientFirstName || ""} ${
                                        patient.patientLastName || ""
                                      }`
                                    )
                                  }
                                  key={patient.patientId}
                                >
                                  <MemberAvatar
                                    name={`${patient?.patientFirstName} ${patient?.patientLastName}`}
                                    size={"small"}
                                    showFullName={true}
                                  />
                                </Dropdown.Item>
                              );
                            })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div
                      className="checkbox-container"
                      onClick={() => markAsProvider(speaker, !providerData[speaker])}
                    >
                      <div
                        className={`custom-checkbox ${!!providerData[speaker] ? "checked" : ""}`}
                      >
                        <span className="checkbox-icon"></span>
                      </div>
                      <div className="checkbox-label">Mark as Provider</div>
                    </div>
                  </div>
                )}
                {props.sessionCategory === "individual" && (
                  <div className="speaker-checkbox-list">
                    <div
                      className="checkbox-container"
                      onClick={() => mapSpeaker(speaker, "Patient")}
                    >
                      <div
                        className={`custom-checkbox ${
                          speakerMappedData[speaker] === "Patient" ? "checked" : ""
                        }`}
                      >
                        <span className="checkbox-icon"></span>
                      </div>
                      <div className="checkbox-label">Patient</div>
                    </div>
                    <div
                      className="checkbox-container"
                      onClick={() => mapSpeaker(speaker, "Provider")}
                    >
                      <div
                        className={`custom-checkbox ${
                          speakerMappedData[speaker] === "Provider" ? "checked" : ""
                        }`}
                      >
                        <span className="checkbox-icon"></span>
                      </div>
                      <div className="checkbox-label">Provider</div>
                    </div>
                    <div
                      className="checkbox-container"
                      onClick={() => mapSpeaker(speaker, "Patient Family")}
                    >
                      <div
                        className={`custom-checkbox ${
                          speakerMappedData[speaker] === "Patient Family" ? "checked" : ""
                        }`}
                      >
                        <span className="checkbox-icon"></span>
                      </div>
                      <div className="checkbox-label">Patient Family</div>
                    </div>
                    <div
                      className="checkbox-container"
                      onClick={() => mapSpeaker(speaker, "Nurse Practitioner")}
                    >
                      <div
                        className={`custom-checkbox ${
                          speakerMappedData[speaker] === "Nurse Practitioner" ? "checked" : ""
                        }`}
                      >
                        <span className="checkbox-icon"></span>
                      </div>
                      <div className="checkbox-label">Nurse Practitioner</div>
                    </div>
                    <div
                      className="checkbox-container"
                      onClick={() => mapSpeaker(speaker, "Unknown")}
                    >
                      <div
                        className={`custom-checkbox ${
                          speakerMappedData[speaker] === "Unknown" ? "checked" : ""
                        }`}
                      >
                        <span className="checkbox-icon"></span>
                      </div>
                      <div className="checkbox-label">Unknown</div>
                    </div>
                  </div>
                )}
              </div>
              <div className="speaker-mapping-transcript-container">
                {transcriptData[speaker].transcripts.map((transcript, index) => {
                  let baseTimeStamp = props.transcriptData?.[0].timestamp;
                  let seconds = transcript.timestamp - baseTimeStamp;
                  let transcriptTime = formatTime(seconds / 1000);
                  return (
                    <div
                      className="transctipt-text"
                      key={index}
                    >
                      {/* <div className="transcript-time">{transcriptTime}</div> */}
                      {transcript.transcriptText}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SpeakerMapping;

function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
}
