import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDyteMeeting, useDyteSelector, useDyteClient } from "@dytesdk/react-web-core";
import { PrimaryButton, SecondaryButton } from "@/components";
import SessionAppointments from "@/Routes/Session/components/SessionAppointments";
import "./SessionLeft.scss";

function SessionLeft(props) {
  const navigate = useNavigate();
  const { sessionCategory, patientId, sessionId } = useParams();
  // const [meeting, initMeeting] = useDyteClient();
  useEffect(() => {
    if (props.meetingToken) {
      // initMeeting({
      //   authToken: props.meetingToken,
      //   defaults: {
      //     audio: false,
      //     video: false,
      //   },
      // });
    }
  }, [props.meetingToken]);
  const joinRoom = m => {
    try {
      m.join();
    } catch (error) {
      setTimeout(() => {
        joinRoom(m);
      }, 5000);
    }
  };
  const rejoinSession = async () => {
    // await meeting.join();
    if (props.meetingToken) {
      let m = await props.initMeeting({
        authToken: props.meetingToken,
        defaults: {
          audio: true,
          video: false,
        },
      });
      if (m) {
        // m?.recording?.on("recordingUpdate", () => {
        //   console.log("recording disabled");
        //   throw "dummyerror";
        // });
        m.recording.updateRecordings = () => {};
        joinRoom(m);
      }
    }
    // window.location.href = window.location.href;
  };
  const endSession = async () => {
    if (props.meetingToken) {
      let m = await props.initMeeting({
        authToken: props.meetingToken,
        defaults: {
          audio: false,
          video: false,
        },
      });
      if (m) {
        // m?.recording?.on("recordingUpdate", () => {
        //   console.log("recording disabled");
        //   throw "dummyerror";
        // });
        m.recording.updateRecordings = () => {};
        await m.join();
        await m?.participants?.kickAll();
      }
    }

    // navigate(`/session-details/${sessionCategory}/virtual/${sessionId}/${patientId}`, {replace: true});
  };
  return (
    <div className="session-left-container">
      <div className="session-left-message">
        You left your session with
        {props.sessionDetailsData?.sessionCategory === "GROUP"
          ? ` ${props.sessionDetailsData?.groupName || ""}`
          : ` ${props.sessionDetailsData?.patientFirstName || ""}${
              props.sessionDetailsData?.patientLastName ? " " : ""
            }${props.sessionDetailsData?.patientLastName || ""}`}
        . You can join again or end the session
      </div>
      <div className="session-left-actions">
        <PrimaryButton onClick={() => rejoinSession()}>Join Again</PrimaryButton>
        <SecondaryButton onClick={() => endSession()}>End Session</SecondaryButton>
      </div>
      <div className="separator">Or</div>
      <div className="start-next-appointment-msg">Start your next appointment</div>
      <div className="your-appointments-container">
        <SessionAppointments
          endSession={endSession}
          sessionEnded={false}
          currentSessionStatus={"Left"}
        />
      </div>
    </div>
  );
}

export default SessionLeft;
