import SessionCard from "./SessionCard";
import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    selectedUserRole: state.user.selectedUserRole,
    preferredTimezone: state.user.preferredTimezone,
    sessionNotesGenerated: state.appointments?.sessions?.sessionNotesGenerated || [],
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionCard);
