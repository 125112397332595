import React, { useState } from "react";
import { MemberAvatar, Loader, SecondaryButton } from "@/components";
import { Tabs, Tab } from "react-bootstrap";
import "./PatientNotes.scss";
import PrivateNotes from "./components/PrivateNotes";
import SoapNotes from "./components/SoapNotes";
import BhPredictions from "./components/BhPredictions";
import TreatmentPlan from "@/components/TreatmentPlan";
import BillingCodes from "./components/BillingCodes";
import Narrative from "./components/Narrative";
import GroupDynamics from "./components/GroupDynamics";
import GroupMemberInsights from "./components/GroupMemberInsights";
import { Link } from "react-router-dom";
import GroupsPlaceholderIcon from "@/assets/group-avatar-placeholder.svg";
import ThemesConflicts from "./components/ThemesConflicts";
import moment from "moment-timezone";
import InPersonIcon from "@/assets/sofa.svg";
import VirtualIcon from "@/assets/video-recorder.svg";
import GroupsIcon from "@/assets/group.svg";
import IntakeIcon from "@/assets/intake.svg";
import Transcript from "../Transcript";
import { SessionStartLink } from "@/Routes/Appointments/components/Sessions/components/SessionCard/SessionCard";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";
const isMobile = window.innerWidth <= 480 || window.innerHeight <= 480;

function PatientNotes(props) {
  const [activeTab, setActiveTab] = useState("soapNotes"); //soapNotes, privateNotes, bhPredictions
  let sessionTime = "";
  if (props.sessionData?.startTime) {
    sessionTime = `${moment(props.sessionData?.startTime)
      .tz(props.preferredTimezone?.name || "America/Los_Angeles")
      .format("MMMM DD")}, ${moment(props.sessionData?.startTime)
      .tz(props.preferredTimezone?.name || "America/Los_Angeles")
      .format("h:mm a")} ${props.preferredTimezone?.abbr || "PT"} | ${
      props.sessionData?.durationInMinutes
    } mins`;
  }
  return (
    <div className="patient-notes-container">
      <div className="patient-notes-header">
        <div className="session-details-patient-info-container">
          <MemberAvatar
            name={
              props.sessionCategory === "group"
                ? props.sessionData?.groupName
                : `${props.sessionData?.patientFirstName} ${props.sessionData?.patientLastName}`
            }
            size={"md"}
            showFullName={true}
            placeholderImg={props.sessionCategory === "group" ? GroupsPlaceholderIcon : null}
          />
          {/* Hiding patient chart as we need to work on structuring the data */}
          {props.sessionCategory !== "group" && (
            <Link
              to={`/patients/${props.patientId}`}
              target="_blank"
              className="view-patient-profile-link"
            >
              {/* View Patient Profile */}
            </Link>
          )}
        </div>
        {props.sessionData && (
          <div className="session-details-data">
            <div className="session-time">
              {props.selectedUserRole?.businessFunction === "Clinical Care" && (
                <SessionStartLink session={props.sessionData} />
              )}
              {sessionTime}
            </div>
            <div className="session-tags-container">
              <div className="session-type">
                <img
                  className="session-type-icon"
                  src={props.sessionData.modeOfDelivery === "VIRTUAL" ? VirtualIcon : InPersonIcon}
                />
                {props.sessionData.modeOfDelivery === "VIRTUAL" ? "Online" : "In-Office"}
              </div>
              {props.sessionData.sessionCategory !== "INDIVIDUAL" && (
                <div className="session-type">
                  <img
                    className="session-type-icon"
                    src={GroupsIcon}
                  />
                  Group
                </div>
              )}
              {props.sessionData.sessionCategory === "INDIVIDUAL" &&
                props.sessionData.appointmentType === "INTAKE" && (
                  <div className="session-type">
                    <img
                      className="session-type-icon"
                      src={IntakeIcon}
                    />
                    Intake
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
      <div className="patient-notes-tabs-container">
        {props.sessionCategory === "group" ? (
          <Tabs
            id="controlled-tab-example"
            activeKey={activeTab}
            onSelect={k => setActiveTab(k)}
            className="patient-notes-tabs-list"
          >
            <Tab
              eventKey="soapNotes"
              title="Group Progress Notes"
            >
              <SoapNotes
                {...props.soapNotes}
                generateSoapNotes={props.generateSoapNotes}
                saveSoapNotes={props.saveSoapNotes}
                sessionId={props.sessionId}
                groupId={props.groupId}
                sessionType={props.sessionType}
                transcriptData={props.transcriptData}
                sessionCategory={props.sessionCategory}
                sessionData={props.sessionData}
                removeTask={props.removeTask}
                preferredTimezone={props.preferredTimezone}
                selectedUserRole={props.selectedUserRole}
              />
            </Tab>
            <Tab
              eventKey="memberInsights"
              title="Member Insights"
            >
              <GroupMemberInsights
                sessionId={props.sessionId}
                {...props.soapNotes}
              />
            </Tab>
            <Tab
              eventKey="groupDynamics"
              title="Group Health"
            >
              <GroupDynamics
                sessionId={props.sessionId}
                {...props.soapNotes}
              />
            </Tab>
            <Tab
              eventKey="groupTheme"
              title="Session Summary"
            >
              <ThemesConflicts
                sessionId={props.sessionId}
                {...props.soapNotes}
              />
            </Tab>
            {APP_ENV === "DEV" && (
              <Tab
                eventKey="billingCodes"
                title="Billing Codes"
              >
                <BillingCodes sessionCategory={props.sessionCategory} />
              </Tab>
            )}
            {!!isMobile && (
              <Tab
                eventKey="transcript"
                title="Transcript"
              >
                <Transcript
                  {...props.sessionTranscript}
                  sessionCategory={props.sessionCategory}
                  sessionData={props.sessionData}
                />
              </Tab>
            )}
          </Tabs>
        ) : (
          <Tabs
            id="controlled-tab-example"
            activeKey={activeTab}
            onSelect={k => setActiveTab(k)}
            className="patient-notes-tabs-list"
          >
            <Tab
              eventKey="soapNotes"
              title={
                props.sessionData?.appointmentType === "INTAKE" ? "Intake Notes" : "SOAP Notes"
              }
            >
              <SoapNotes
                data={props.soapNotes.data}
                notesId={props.soapNotes.notesId}
                loading={props.soapNotes.loading}
                soapNotesJson={props.soapNotes.soapNotesJson}
                generateSoapNotes={props.generateSoapNotes}
                saveSoapNotes={props.saveSoapNotes}
                sessionId={props.sessionId}
                patientId={props.patientId}
                sessionType={props.sessionType}
                transcriptData={props.transcriptData}
                sessionCategory={props.sessionCategory}
                sessionData={props.sessionData}
                removeTask={props.removeTask}
                preferredTimezone={props.preferredTimezone}
                selectedUserRole={props.selectedUserRole}
              />
            </Tab>
            <Tab
              eventKey="bhPredictions"
              title="Behavioural Health Prediction"
            >
              <BhPredictions
                {...props.bhPredictions}
                generateBhPredictions={props.generateBhPredictions}
                saveBhPredictions={props.saveBhPredictions}
                sessionId={props.sessionId}
                groupId={props.groupId}
                patientId={props.patientId}
                sessionType={props.sessionType}
                transcriptData={props.transcriptData}
                sessionData={props.sessionData}
              />
            </Tab>
            {/* {APP_ENV === "DEV" && ( */}
            <Tab
              eventKey="treatmentPlan"
              title="Treatment Plan"
            >
              <TreatmentPlan patientId={props.patientId} />
            </Tab>
            {/* )} */}

            {/* <Tab
              eventKey="privateNotes"
              title="Private Notes"
            >
              <PrivateNotes
                {...props.memberNotes}
                updateMemberNotes={props.updateMemberNotes}
                sessionId={props.sessionId}
                patientId={props.patientId}
                sessionType={props.sessionType}
                sessionCategory={props.sessionCategory}
              />
            </Tab> */}
            <Tab
              eventKey="narrative"
              title="Narrative"
            >
              <Narrative
                soapNote={props.soapNotes.data}
                notesId={props.soapNotes.notesId}
                loading={props.narrative.loading}
                soapNotesJson={props.soapNotes.soapNotesJson}
                generateNarrative={props.generateNarrative}
                sessionId={props.sessionId}
                patientId={props.patientId}
                sessionType={props.sessionType}
                transcriptData={props.transcriptData}
                sessionCategory={props.sessionCategory}
                sessionData={props.sessionData}
                saveNarrativeData={props.saveNarrativeData}
              />
            </Tab>
            {APP_ENV === "DEV" && (
              <Tab
                eventKey="billingCodes"
                title="Billing Codes"
              >
                <BillingCodes sessionCategory={props.sessionCategory} />
              </Tab>
            )}
            {!!isMobile && (
              <Tab
                eventKey="transcript"
                title="Transcript"
              >
                <Transcript
                  {...props.sessionTranscript}
                  sessionCategory={props.sessionCategory}
                  sessionData={props.sessionData}
                />
              </Tab>
            )}
          </Tabs>
        )}
      </div>
    </div>
  );
}

export default PatientNotes;
