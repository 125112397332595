import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Avatar, CrisisSupport } from "@/components";
import SoulsideLogo from "@/assets/soulside-logo.svg";
import "./SideNav.scss";

const DASHBOARD_TYPE = import.meta.env.VITE_DASHBOARD_TYPE;

function SideNav(props) {
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [showCrisisSupport, setShowCrisisSupport] = useState(false);
  let location = useLocation();
  const inSessionRegex = /^\/session\/.+$/;
  const inPersonSessionRegex = /^\/session\/.+$/;
  const sessionEndedRegex = /^\/session-ended\/.+$/;
  const postSessionRegex = /^\/session-details\/.+$/;
  const openLinkInNewTab = inSessionRegex.test(location.pathname) && false;
  // ||
  // postSessionRegex.test(location.pathname) ||
  // inPersonSessionRegex.test(location.pathname);
  let selectedUserRole = props.selectedUserRole;
  let businessFunction = selectedUserRole?.businessFunction;
  return (
    <div className="side-nav-container">
      <div className="side-nav-top">
        <div className="profile-info">
          {!props.userLoading && (
            <NavLink
              to={"/profile"}
              style={{ textDecoration: "none" }}
              target={openLinkInNewTab ? "_blank" : ""}
            >
              <Avatar
                name={`${props.userData?.firstName || ""}${props.userData?.lastName ? " " : ""}${
                  props.userData?.lastName || ""
                }`}
                imageSrc={props.userData?.preSignedUrlForProfileImage}
              />
            </NavLink>
          )}
        </div>
        {!!selectedUserRole && (
          <div className="nav-tabs-list">
            {/* <NavLink
              className={"nav-tab home"}
              to={"/"}
              target={openLinkInNewTab ? "_blank" : ""}
            >
              <span className="nav-tab-icon"></span>
              <div className="nav-tab-name">Dashboard</div>
            </NavLink> */}
            <NavLink
              className={"nav-tab appointments"}
              to={"/"}
              target={openLinkInNewTab ? "_blank" : ""}
            >
              <span className="nav-tab-icon"></span>
              <div className="nav-tab-name">Appointments</div>
            </NavLink>
            <NavLink
              className={"nav-tab patients"}
              to={"/patients"}
              target={openLinkInNewTab ? "_blank" : ""}
            >
              <span className="nav-tab-icon"></span>
              <div className="nav-tab-name">Patients</div>
            </NavLink>
            <NavLink
              className={"nav-tab groups"}
              to={"/groups"}
              target={openLinkInNewTab ? "_blank" : ""}
            >
              <span className="nav-tab-icon"></span>
              <div className="nav-tab-name">Groups</div>
            </NavLink>
            {businessFunction === "Management and Leadership" && (
              <NavLink
                className={"nav-tab users"}
                to={"/users"}
                target={openLinkInNewTab ? "_blank" : ""}
              >
                <span className="nav-tab-icon"></span>
                <div className="nav-tab-name">Users</div>
              </NavLink>
            )}
            <div
              className="nav-tab crisis"
              onClick={() => setShowCrisisSupport(true)}
            >
              <span className="nav-tab-icon"></span>
              <div className="nav-tab-name">Crisis</div>
            </div>
          </div>
        )}
      </div>
      <div className="side-nav-bottom">
        <div className="side-nav-logo">
          <img
            src={SoulsideLogo}
            alt="Soulside"
          />
        </div>
      </div>
      <SupportModal
        modalOpen={supportModalOpen}
        toggleModal={() => setSupportModalOpen(false)}
        userData={props.userData}
      />
      <CrisisSupport
        modalOpen={showCrisisSupport}
        toggleModal={setShowCrisisSupport}
      />
    </div>
  );
}

export default SideNav;

const SupportModal = props => {
  return (
    <Modal
      show={props.modalOpen}
      onHide={props.toggleModal}
      className="support-modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <iframe
          title="Form Name"
          src={`https://form.jotform.com/240093983099063?email=${
            props.userData?.email || ""
          }&userId=${props.userData?.id || ""}`}
          style={{
            width: "100%",
            height: "600px",
          }}
        />
      </Modal.Body>
    </Modal>
  );
};
