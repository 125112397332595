import Appointments from "./Appointments";
import { connect } from "react-redux";
import { getSessionsByPractitionerRole } from "./appointmentsModule";
import { selectPreferredTimezone } from "@/store/modules/userModule";
import { getOrgUsers } from "../Users/usersModule";

const mapStateToProps = state => {
  return {
    sessions: state.appointments.sessions,
    selectedUserRole: state.user.selectedUserRole,
    preferredTimezone: state.user.preferredTimezone,
    orgUsers: state.orgUsers.users,
    userData: state.user.userData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSessionsByPractitionerRole: options => dispatch(getSessionsByPractitionerRole(options)),
    getOrgUsers: options => dispatch(getOrgUsers(options)),
    selectPreferredTimezone: timezone => dispatch(selectPreferredTimezone(timezone)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
