import React, { useState } from "react";
import { MemberAvatar, Loader, SecondaryButton } from "@/components";
import "./Transcript.scss";

function Transcript(props) {
  const [transcriptCopied, setTranscriptCopied] = useState(false);
  const providerName = `${props.sessionData?.practitionerFirstName || ""}${
    props.sessionData?.practitionerLastName ? " " : ""
  }${props.sessionData?.practitionerLastName || ""}`;
  const patientName = `${props.sessionData?.patientFirstName || ""}${
    props.sessionData?.patientLastName ? " " : ""
  }${props.sessionData?.patientLastName || ""}`;
  const copyTranscript = () => {
    let transcriptText = "";
    let transcriptData = props.data;
    transcriptData.forEach(transcript => {
      let memberName = transcript.memberName;
      if (
        ["provider", "patient"].includes(memberName?.toLowerCase()) &&
        props.sessionCategory === "individual"
      ) {
        if (memberName?.toLowerCase() === "provider" && providerName) {
          memberName = providerName;
        }
        if (memberName?.toLowerCase() === "patient" && patientName) {
          memberName = patientName;
        }
      }
      transcriptText += `${memberName}: ${transcript.transcriptText}\n\n`;
    });
    navigator.clipboard
      .writeText(transcriptText)
      .then(() => {
        setTranscriptCopied(true);
        setTimeout(() => {
          setTranscriptCopied(false);
        }, 3000);
      })
      .catch(err => {
        console.error("Unable to copy text to clipboard", err);
      });
  };
  return (
    <div className="session-transcript-container">
      <div className="session-transcript-header">
        <div className="session-transcript-title">Transcript</div>
        {props.selectedUserRole?.businessFunction === "Management and Leadership" && (
          <SecondaryButton onClick={() => props.setSpeakerMappingOpen(true)}>
            Speaker Mapping
          </SecondaryButton>
        )}
        <SecondaryButton onClick={copyTranscript}>
          {transcriptCopied ? "Transcript Copied" : "Copy Transcript"}
        </SecondaryButton>
      </div>
      <Loader loading={props.loading}>
        {props.data.length > 0 ? (
          <div className="session-transcript-content">
            {props.data.map((transcript, index) => {
              let baseTimeStamp = props.data?.[0].timestamp;
              let seconds = transcript.timestamp - baseTimeStamp;
              let transcriptTime = formatTime(seconds / 1000);
              let memberName = transcript.memberName;
              let transcriptText = transcript.transcriptText;
              if (
                ["provider", "patient"].includes(memberName?.toLowerCase()) &&
                props.sessionCategory === "individual"
              ) {
                if (memberName?.toLowerCase() === "provider" && providerName) {
                  memberName = providerName;
                }
                if (memberName?.toLowerCase() === "patient" && patientName) {
                  memberName = patientName;
                }
              }
              return (
                <div
                  className={`transcript-item`}
                  key={`${transcript.timestamp}-${transcript.memberId}-${convertStringToHash(
                    transcriptText
                  )}-${index}`}
                >
                  <div className="transcript-item-header">
                    <div className="transcript-avatar">
                      {/* <MemberAvatar
                        name={memberName}
                        size={"sm"}
                      /> */}
                      {memberName}
                    </div>
                    <div className="transcript-time">{transcriptTime}</div>
                  </div>
                  <div className="transcript-text">{transcriptText}</div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="no-transcript-container">
            No Transcripts Found. Please check back again later.
          </div>
        )}
      </Loader>
    </div>
  );
}

export default Transcript;

const convertStringToHash = str => {
  var hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
}
