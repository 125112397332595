import AppLayout from "./AppLayout";
import { connect } from "react-redux";
import { getUserData } from "@/store/modules/userModule";
import { getSessionsByPractitionerRole } from "@/Routes/Appointments/appointmentsModule";

const mapStateToProps = state => {
  return {
    userLoading: state.user.userLoading,
    userData: state.user.userData,
    selectedUserRole: state.user.selectedUserRole,
    hideSideBar: state.app.hideSideBar,
    sessionNotesPdfStatus: state.appointments.sessionNotesPdfStatus,
    preferredTimezone: state.user.preferredTimezone,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserData: options => dispatch(getUserData(options)),
    getSessionsByPractitionerRole: options => dispatch(getSessionsByPractitionerRole(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
