import React, { useEffect, useState } from "react";
import { Modal, Form, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import { Loader, MemberAvatar, PrimaryButton, Search } from "@/components";
import { post } from "@/services/api";
import { addLocalStorage, getLocalStorage } from "@/services/utils";
import moment from "moment-timezone";
import InPersonIcon from "@/assets/sofa.svg";
import VirtualIcon from "@/assets/video-recorder.svg";
import IndividualIcon from "@/assets/individual.svg";
import PhoneSpeaker from "@/assets/phone-speaker.svg";
import GroupIcon from "@/assets/group.svg";
import "./StartSessionModal.scss";

function StartSessionModal(props) {
  const navigate = useNavigate();
  const [sessionCategory, setSessionCategory] = useState("individual"); //individual, group
  const [sessionType, setSessionType] = useState("virtual"); //in-person,virtual, external-speaker-phone
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [durationInMinutes, setDurationInMinutes] = useState(30);
  const [loading, setLoading] = useState(false);
  const [appointmentType, setAppointmentType] = useState("FOLLOW_UP"); //FOLLOW_UP, INTAKE
  const [patientSearch, setPatientSearch] = useState("");
  // const patientsList = props.patients.data;
  const patientsList = patientSearch
    ? props.patients.data?.filter(i => {
        let patientName = `${i.firstName} ${i.lastName}`;
        return !!patientName.toLowerCase().includes(patientSearch.toLowerCase());
      })
    : props.patients.data;
  useEffect(() => {
    if (patientsList?.length === 0 && !props.patients.loading) {
      props.getPatients();
    }
  }, []);
  const groupsList = props.groups.data;
  useEffect(() => {
    if (groupsList?.length === 0 && !props.groups.loading) {
      props.getGroups();
    }
  }, []);
  useEffect(() => {
    setSessionCategory("individual");
    setSessionType(props.sessionType || "virtual");
    setSelectedPatient(null);
    setSelectedGroup(null);
    setDurationInMinutes(30);
    setAppointmentType("FOLLOW_UP");
    setLoading(false);
    setPatientSearch("");
  }, [props.modalOpen]);
  const startSession = async () => {
    let sessionId = await scheduleSession();
    const patientId = selectedPatient?.id;
    const groupId = selectedGroup?.id;
    let url = `/session/${sessionCategory}/${
      sessionType === "external-speaker-phone" ? "in-person" : sessionType
    }/${sessionId}/${sessionCategory === "group" ? groupId : patientId}${
      sessionType === "external-speaker-phone" ? "?externalSpeakerPhone=true" : ""
    }`;
    navigate(url);
  };
  const scheduleSession = async () => {
    let sessionId = "";
    let url = "practitioner-role/individual-session/create";
    let patientName = `${selectedPatient?.firstName} ${selectedPatient?.lastName}`;
    let groupName = selectedGroup?.groupName;
    let sessionName =
      sessionCategory === "individual"
        ? `${
            sessionType === "in-person"
              ? "In-Office session with"
              : sessionType === "external-speaker-phone"
              ? "Phone session with"
              : "Telehealth session with"
          } ${patientName}`
        : `${
            sessionType === "in-person"
              ? "In-Office session for"
              : sessionType === "external-speaker-phone"
              ? "Phone session for"
              : "Telehealth session for"
          } ${groupName}`;
    let offsetStartDateTime = new Date().toISOString();
    let payload = {
      practitionerRoleId: props.practitionerRoleId,
      sessionName,
      offsetStartDateTime,
      durationInMinutes,
      sessionCategory: sessionCategory === "group" ? "GROUP" : "INDIVIDUAL",
      modeOfDelivery:
        sessionType === "in-person" || sessionType === "external-speaker-phone"
          ? "IN_PERSON"
          : "VIRTUAL",
      appointmentType: sessionCategory === "group" ? "FOLLOW_UP" : appointmentType,
    };
    if (sessionCategory === "group") {
      url = "practitioner-role/sessions/create-on-demand";
      payload.groupId = selectedGroup?.id;
    } else {
      payload.patientId = selectedPatient?.id;
    }
    setLoading(true);
    let sessionData = null;
    try {
      let response = await post(url, payload);
      if (response?.data) {
        sessionId = response.data?.id;
        sessionData = response.data;
      }
    } catch (error) {}
    setLoading(false);
    if (sessionData) {
      if (props.filterSelectedDate) {
        let startDateTime = moment.tz(
          props.filterSelectedDate,
          props.preferredTimezone?.name || "America/Los_Angeles"
        );
        startDateTime = startDateTime.hour(0);
        startDateTime = startDateTime.minute(0);
        startDateTime = startDateTime.second(0);
        startDateTime = startDateTime.format();
        let endDateTime = moment.tz(
          props.filterSelectedDate,
          props.preferredTimezone?.name || "America/Los_Angeles"
        );
        endDateTime = endDateTime.hour(23);
        endDateTime = endDateTime.minute(59);
        endDateTime = endDateTime.second(59);
        endDateTime = endDateTime.format();
        props.getSessionsByPractitionerRole({ startDateTime, endDateTime });
      }
    }
    return sessionId;
  };
  return (
    <Modal
      show={props.modalOpen}
      onHide={() => props.toggleModal(false)}
      className="start-session-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Start Instant Session</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="start-session-modal-body">
          <div className="session-type-container">
            <div className="session-type-label">Session Category:</div>
            <div className="session-type">
              <Form.Check
                type="radio"
                label={
                  <div className="session-type-option-label">
                    <img
                      className="session-type-option-icon"
                      src={IndividualIcon}
                    />
                    Individual
                  </div>
                }
                name={"individual"}
                id={`session-category-individual`}
                checked={sessionCategory === "individual"}
                onChange={() => setSessionCategory("individual")}
                className="session-type-option-radio-button"
              />
            </div>
            <div className="session-type">
              <Form.Check
                type="radio"
                label={
                  <div className="session-type-option-label">
                    <img
                      className="session-type-option-icon"
                      src={GroupIcon}
                    />
                    Group
                  </div>
                }
                name={"group"}
                id={`session-category-group`}
                checked={sessionCategory === "group"}
                onChange={() => setSessionCategory("group")}
                className="session-type-option-radio-button"
              />
            </div>
          </div>
          <div className="session-type-container mode-of-delivery-container">
            <div className="session-type-label">Mode of Delivery:</div>
            <div className="session-type">
              <Form.Check
                type="radio"
                label={
                  <div className="session-type-option-label">
                    <img
                      className="session-type-option-icon"
                      src={VirtualIcon}
                    />
                    Telehealth
                  </div>
                }
                name={"virtual"}
                id={`session-type-virtual`}
                checked={sessionType === "virtual"}
                onChange={() => setSessionType("virtual")}
                className="session-type-option-radio-button"
              />
            </div>
            <div className="session-type">
              <Form.Check
                type="radio"
                label={
                  <div className="session-type-option-label">
                    <img
                      className="session-type-option-icon"
                      src={InPersonIcon}
                    />
                    In-Office
                  </div>
                }
                name={"in-person"}
                id={`session-type-in-person`}
                checked={sessionType === "in-person"}
                onChange={() => setSessionType("in-person")}
                className="session-type-option-radio-button"
              />
            </div>
            {/* <div className="session-type">
              <Form.Check
                type="radio"
                label={
                  <div className="session-type-option-label">
                    <img
                      className="session-type-option-icon"
                      src={PhoneSpeaker}
                    />
                    Phone Speaker
                  </div>
                }
                name={"external-speaker-phone"}
                id={`session-type-external-speaker-phone`}
                checked={sessionType === "external-speaker-phone"}
                onChange={() => setSessionType("external-speaker-phone")}
                className="session-type-option-radio-button"
              />
            </div> */}
          </div>
          {sessionCategory === "individual" && (
            <div className="session-type-container">
              <div className="session-type-label">Session Type:</div>
              <div className="session-type">
                <Form.Check
                  type="radio"
                  label={<div className="session-type-option-label">Follow Up</div>}
                  name={"follow-up"}
                  id={`session-type-follow-up`}
                  checked={appointmentType === "FOLLOW_UP"}
                  onChange={() => setAppointmentType("FOLLOW_UP")}
                  className="session-type-option-radio-button"
                />
              </div>
              <div className="session-type">
                <Form.Check
                  type="radio"
                  label={<div className="session-type-option-label">Intake</div>}
                  name={"intake"}
                  id={`session-type-intake`}
                  checked={appointmentType === "INTAKE"}
                  onChange={() => setAppointmentType("INTAKE")}
                  className="session-type-option-radio-button"
                />
              </div>
            </div>
          )}
          <div className="input-row">
            {sessionCategory === "individual" && (
              <div className="select-patient-container">
                <Form.Label className="select-patient-label">Select Patient:</Form.Label>
                <Dropdown className="select-patient-dropdown">
                  <Dropdown.Toggle>
                    {!!selectedPatient
                      ? `${selectedPatient?.firstName} ${selectedPatient?.lastName}`
                      : "Select Patient"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Search
                      placeholder={"Search for a patient"}
                      value={patientSearch}
                      onSearch={val => setPatientSearch(val)}
                      clearSearch={() => setPatientSearch("")}
                      noDebounce={true}
                    />
                    <Loader loading={false}>
                      {patientsList.length > 0 &&
                        patientsList.map(patient => {
                          return (
                            <Dropdown.Item
                              as={"button"}
                              onClick={() => setSelectedPatient(patient)}
                              key={patient.id}
                            >
                              <MemberAvatar
                                name={`${patient?.firstName} ${patient?.lastName}`}
                                size={"small"}
                                showFullName={true}
                              />
                            </Dropdown.Item>
                          );
                        })}
                    </Loader>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            {sessionCategory === "group" && (
              <div className="select-patient-container">
                <Form.Label className="select-patient-label">Select Group:</Form.Label>
                <Dropdown className="select-patient-dropdown">
                  <Dropdown.Toggle>
                    {!!selectedGroup ? `${selectedGroup?.groupName}` : "Select Group"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Loader loading={false}>
                      {groupsList.length > 0 &&
                        groupsList.map(group => {
                          return (
                            <Dropdown.Item
                              as={"button"}
                              onClick={() => setSelectedGroup(group)}
                              key={group.id}
                            >
                              {group.groupName}
                            </Dropdown.Item>
                          );
                        })}
                    </Loader>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            <div className="select-patient-container">
              <Form.Label className="select-patient-label">Duration of Session:</Form.Label>
              <Dropdown className="select-patient-dropdown">
                <Dropdown.Toggle>
                  {!!durationInMinutes ? `${durationInMinutes} mins` : "Select Duration"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Loader loading={false}>
                    {[15, 30, 45, 60].map((duration, index) => {
                      return (
                        <Dropdown.Item
                          // as={"button"}
                          onClick={() => setDurationInMinutes(duration)}
                          key={index}
                        >
                          {duration} mins
                        </Dropdown.Item>
                      );
                    })}
                  </Loader>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton
          onClick={startSession}
          disabled={
            loading ||
            (sessionCategory === "group" ? !selectedGroup?.id : !selectedPatient?.id) ||
            !durationInMinutes
          }
        >
          Start Session
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}

export default StartSessionModal;
