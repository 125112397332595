import React, { useState, useEffect } from "react";
import "./Appointments.scss";
import Sessions from "./components/Sessions";
import { Form, Dropdown } from "react-bootstrap";
import { PrimaryButton } from "@/components";
import { timezones } from "@/store/modules/userModule";
import EHRLogo from "@/assets/ehr-logo.png";
import CalendarDaysIcon from "@/assets/calendar-days.svg";
import Tasks from "../Home/components/Tasks";

function Appointments(props) {
  const practitionerRoleId = props.selectedUserRole?.id;
  // useEffect(() => {
  //   if (!props.sessions.loading && props.sessions.data.length === 0) {
  //     props.getSessionsByPractitionerRole();
  //   }
  //   props.getSessionsByPractitionerRole();
  // }, []);
  useEffect(() => {
    document.title = "Appointments";
  }, []);
  useEffect(() => {
    console.log("patientMentalWellnessData", props.sessions.patientMentalWellnessData);
  }, [props.sessions?.patientMentalWellnessData]);
  useEffect(() => {
    console.log("groupMentalWellnessData", props.sessions.groupMentalWellnessData);
  }, [props.sessions?.groupMentalWellnessData]);
  return (
    <div className="appointments-container">
      <div className="appointments-header-container">
        <div className="appointments-welcome-container">
          <div className="appointments-welcome-msg">Hello {props.userData?.firstName}!</div>
        </div>
        <div className="appointments-time-container">
          <div className="preferred-timezone-container">
            <Form.Label className="select-timezone-label">Preferred Timezone:</Form.Label>
            <Dropdown className="select-timezone-dropdown">
              <Dropdown.Toggle>
                {props.preferredTimezone?.name || ""} | {props.preferredTimezone?.abbr}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {timezones.map(timezone => {
                  return (
                    <Dropdown.Item
                      as={"button"}
                      key={timezone.name}
                      onClick={() => props.selectPreferredTimezone(timezone)}
                      className="timezone-item"
                    >
                      <div className="timezone-name">
                        {timezone.name} <span className="timezone-abbr">{timezone.abbr}</span>
                      </div>
                      <div className="timezone-offset">UTC {formatTimeOffset(timezone.offset)}</div>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {/* <TodayDateTime preferredTimezone={props.preferredTimezone}/> */}
        </div>
      </div>
      <div className="appointments-page">
        <Sessions
          sessions={props.sessions}
          practitionerRoleId={practitionerRoleId}
          selectedUserRole={props.selectedUserRole}
          getSessionsByPractitionerRole={props.getSessionsByPractitionerRole}
          preferredTimezone={props.preferredTimezone}
          orgUsers={props.orgUsers}
          getOrgUsers={props.getOrgUsers}
        />
        <div className="appointments-task-container">
          <Tasks />
        </div>
        {/* <div className="ehr-not-integrated-container">
          <div className="ehr-logo-container">
            <img
              src={EHRLogo}
              alt=""
              className="ehr-logo"
            />
            <span>+</span>
            <img
              src={CalendarDaysIcon}
              alt=""
              className="ehr-logo"
            />
          </div>
          <div className="ehr-integration-title">Connect your EHR Platform</div>
          <div className="ehr-integration-message">
            Sync all your appointments by connecting your EHR Platform.
          </div>
          {props.selectedUserRole?.businessFunction === "Clinical Care" && false && (
            <>
              <div className="ehr-container-separator">OR</div>
              <PrimaryButton onClick={() => props.setShowStartSessionModal(true)}>
                Start an Instant Session
              </PrimaryButton>
            </>
          )}
        </div> */}
      </div>
    </div>
  );
}

export default Appointments;

function formatTimeOffset(offset) {
  // Determine if the offset is positive or negative
  const sign = offset >= 0 ? "+" : "-";

  // Take the absolute value of the offset
  const absoluteOffset = Math.abs(offset);

  // Extract hours and minutes
  const hours = Math.floor(absoluteOffset);
  const minutes = (absoluteOffset % 1) * 60;

  // Format hours and minutes to two digits
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  // Combine into the final format
  return `${sign}${formattedHours}:${formattedMinutes}`;
}
