import { get, post } from "@/services/api";
import { getLocalStorage } from "@/services/utils";
import { getSessionsByPractitionerRole } from "../Appointments/appointmentsModule";

export const getPatients = options => {
  return async (dispatch, getState) => {
    const state = getState();
    const orgId = state.user.selectedUserRole?.organizationId;
    let url = `practitioner-role/patient/find-all-by-org/${orgId}`;
    let data = [];
    dispatch({ type: "TOGGLE_PATIENTS_LOADER", show: true });
    try {
      let response = await get(url);
      let patientsData = response.data?.length > 0 ? response.data : [];
      if (options?.fromPatientsPage) {
        dispatch({ type: "TOGGLE_PATIENTS_ALERTS_SAVED", flag: true });
        for (let i = 0; i < patientsData.length; i++) {
          let completedSessions = await dispatch(getPatientCompletedSessions(patientsData[i].id));
          let patientAlerts = await getPatientAlerts(
            completedSessions.map(i => i.id),
            patientsData[i].id
          );
          data.push({
            ...patientsData[i],
            alerts: patientAlerts,
          });
        }
      } else {
        data = patientsData;
      }
    } catch (error) {
      console.log(error);
    }
    dispatch({ type: "ADD_PATIENTS_DATA", data });
    dispatch({ type: "TOGGLE_PATIENTS_LOADER", show: false });
  };
};

export const addPatient = options => {
  return async (dispatch, getState) => {
    const state = getState();
    const organizationId = state.user.selectedUserRole?.organizationId;
    const organizationName = state.user.selectedUserRole?.organizationName;
    let payload = {
      organizationId,
      organizationName,
      firstName: options?.firstName,
      lastName: options?.lastName,
      email: options?.email?.toLowerCase()?.trim() || "",
    };
    if (options?.phoneNumber) {
      payload.phoneNumber = options.phoneNumber;
    }
    let url = "practitioner-role/patient/create";
    try {
      let response = await post(url, payload);
      if (response?.data) {
        dispatch({ type: "ADD_PATIENT", data: response.data });
      }
    } catch (error) {}
  };
};

const getPatientCompletedSessions = patientId => {
  return async (dispatch, getState) => {
    let state = getState();
    let allSessions = state.appointments.sessions?.data || [];
    if (allSessions.length === 0) {
      allSessions = await dispatch(getSessionsByPractitionerRole());
    }
    let completedSessions = allSessions.filter(
      session =>
        !!session.endTime &&
        new Date(session.endTime) < new Date() &&
        session.sessionCategory === "INDIVIDUAL"
    );
    completedSessions = completedSessions.sort(
      (session1, session2) => new Date(session2.startTime) - new Date(session1.startTime)
    );
    completedSessions = completedSessions.filter(i => i.patientId === patientId);
    completedSessions = completedSessions.filter(i => i.appointmentType !== "INTAKE");
    return completedSessions;
  };
};

// const getPatientCompletedSessions1 = patientId => {
//   let completedSessions = getLocalStorage("completedSessions") || [];
//   completedSessions = completedSessions.map(session => {
//     return {
//       ...session,
//       patientData: patientsData.find(i => i.patient_id === session.patientId) || null,
//     };
//   });
//   completedSessions = completedSessions.sort(
//     (session1, session2) => new Date(session2.sessionDate) - new Date(session1.sessionDate)
//   );
//   completedSessions = completedSessions.filter(i => i.patientId === patientId);
//   return completedSessions;
// };

const getPatientAlerts = async (sessionIds, patientId) => {
  let latestBhPredictions = null;
  let alerts = null;
  for (let i = 0; i < sessionIds.length; i++) {
    let sessionId = sessionIds[i];
    let sessionSoapNotes = await getBhPredictionsData(sessionId);
    let userSoapNote = sessionSoapNotes.find(i => i.patientId === patientId);
    if (userSoapNote?.behaviouralHealthPredictions) {
      latestBhPredictions = userSoapNote.behaviouralHealthPredictions;
    }
    if (latestBhPredictions) {
      break;
    }
  }
  alerts = getAlerts(latestBhPredictions);
  return alerts;
};

const getBhPredictionsData = async sessionId => {
  let sessionSoapNotes = [];
  let url = `practitioner-role/session-member-notes/session/${sessionId}/find`;
  try {
    let response = await get(url);
    if (response?.data?.length > 0) {
      sessionSoapNotes = response.data;
    }
  } catch (error) {}
  return sessionSoapNotes;
};

const getAlerts = behaviouralHealthPredictions => {
  let behaviouralHealthPredictionsData = null;
  if (behaviouralHealthPredictions) {
    let data = behaviouralHealthPredictions || null;
    if (data && Object.keys(data).length > 0) {
      let anxietyParameters = data?.["gad-7"] || {};
      let depressionParameters = data?.["phq-9"] || {};
      anxietyParameters = Object.keys(anxietyParameters).map(parameter => {
        return {
          title: parameter,
          detected: anxietyParameters[parameter] > 0,
          intensity: anxietyParameters[parameter],
        };
      });
      depressionParameters = Object.keys(depressionParameters).map(parameter => {
        return {
          title: parameter,
          detected: depressionParameters[parameter] > 0,
          intensity: depressionParameters[parameter],
        };
      });
      let anxietyScore = anxietyParameters.reduce((result, i) => {
        return i.intensity + result;
      }, 0);
      let depressionScore = depressionParameters.reduce((result, i) => {
        return i.intensity + result;
      }, 0);
      let anxietyIntensityText =
        anxietyScore >= 15
          ? "Severe"
          : anxietyScore >= 10
          ? "Moderate"
          : anxietyScore >= 5
          ? "Mild"
          : "Low";
      let depressionIntensityText =
        depressionScore >= 20
          ? "Severe"
          : depressionScore >= 15
          ? "Moderately severe"
          : depressionScore >= 10
          ? "Moderate"
          : depressionScore >= 5
          ? "Mild"
          : "Low";
      let anxietyData = {
        parameters: anxietyParameters,
        score: anxietyScore,
        intensityText: anxietyIntensityText,
        cardColor: getIntensityColor(anxietyIntensityText),
      };
      let depressionData = {
        parameters: depressionParameters,
        score: depressionScore,
        intensityText: depressionIntensityText,
        cardColor: getIntensityColor(depressionIntensityText),
      };
      let emotionalDistress = {
        intensityText: data?.emotional_distress || "-",
        cardColor: getIntensityColor(data?.emotional_distress, "emotional_distress"),
      };
      let suicideRisk = {
        intensityText: data?.suicide_risk || "",
        cardColor: getIntensityColor(data?.suicide_risk),
      };
      let predictData = {
        anxiety: anxietyData,
        depression: depressionData,
        emotionalDistress,
        suicideRisk,
      };
      behaviouralHealthPredictionsData = predictData;
    }
  }
  return behaviouralHealthPredictionsData;
};

const getIntensityColor = (intensity, key) => {
  // let color = "#74dd74";
  let color = "low";
  let intensitySmallCase = intensity?.toLowerCase() || "";
  if (intensitySmallCase.includes("severe") || intensitySmallCase.includes("present")) {
    // color = "#FF5F05";
    color = "severe";
  } else if (
    intensitySmallCase.includes("moderate") ||
    (key === "emotional_distress" && intensitySmallCase.includes("high"))
  ) {
    // color = "#FF5F05";
    color = "high";
  } else if (
    intensitySmallCase.includes("mild") ||
    (key === "emotional_distress" && intensitySmallCase.includes("medium"))
  ) {
    // color = "#64C833";
    color = "medium";
  } else if (
    intensitySmallCase.includes("low") ||
    (key === "emotional_distress" && intensitySmallCase.includes("low"))
  ) {
    // color = "44C13C";
    color = "low";
  }
  return color;
};

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

export const getDummyPhoneNumber = () => {
  return (dispatch, getState) => {
    const state = getState();
    const orgName = state.user.selectedUserRole?.organizationName;
    let phoneNumber = "-";
    if (APP_ENV === "DEV" && orgName === "Soulside Inc") {
      phoneNumber = Math.random().toString().slice(2, 12);
    }
    return phoneNumber;
  };
};

export const getDummyEmail = name => {
  return (dispatch, getState) => {
    const state = getState();
    const orgName = state.user.selectedUserRole?.organizationName;
    let email = "-";
    if (name && APP_ENV === "DEV" && orgName === "Soulside Inc") {
      email = `${name.toLowerCase()}@gmail.com`;
    }
    return email;
  };
};

const initState = {
  alertsSaved: false,
  patients: {
    data: [],
    loading: false,
  },
};

const ACTION_HANDLERS = {
  ["TOGGLE_PATIENTS_LOADER"]: (state, action) => {
    return {
      ...state,
      patients: {
        ...state.patients,
        loading: action.show,
      },
    };
  },
  ["ADD_PATIENTS_DATA"]: (state, action) => {
    return {
      ...state,
      patients: {
        ...state.patients,
        data: action.data,
      },
    };
  },
  ["ADD_PATIENT"]: (state, action) => {
    return {
      ...state,
      patients: {
        ...state.patients,
        data:
          state.patients.data?.length > 0 ? [action.data, ...state.patients.data] : [action.data],
      },
    };
  },
  ["TOGGLE_PATIENTS_ALERTS_SAVED"]: (state, action) => {
    return {
      ...state,
      alertsSaved: action.flag,
    };
  },
};

export default function patients(state = initState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
