import React, { useState, useEffect } from "react";
import SessionCard from "@/Routes/Appointments/components/Sessions/components/SessionCard";
import { Loader, MemberAvatar, Search } from "@/components";
import { addLocalStorage, checkTodaySession, getLocalStorage } from "@/services/utils";
import { roleBusinessFunctionMapping } from "@/services/dummyData";
import { Form, Dropdown } from "react-bootstrap";
import moment from "moment-timezone";
import "./UpcomingSessions.scss";

function UpcomingSessions(props) {
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [providerSearch, setProviderSearch] = useState("");
  let loading = props.sessions?.loading;
  let upcomingSessions = props.sessions.data.filter(checkTodaySession);
  upcomingSessions = upcomingSessions.sort(
    (session1, session2) => new Date(session1.startTime) - new Date(session2.startTime)
  );
  if (selectedProvider) {
    upcomingSessions = upcomingSessions.filter(
      session => session.practitionerId === selectedProvider.practitionerId
    );
  }
  useEffect(() => {
    if (!props.sessions.loading && props.sessions.data.length === 0) {
      props.getTodaysSessionsByPractitionerRole();
    }
  }, []);
  const orgUsersData = props.orgUsers.data || [];
  let providersList = [];
  if (orgUsersData?.length > 0) {
    orgUsersData.forEach(user => {
      user.roles.forEach(role => {
        if (roleBusinessFunctionMapping(role.behaviorHealthRole) === "Clinical Care") {
          providersList.push({
            ...role,
            practitionerEmail: user.practitionerEmail,
            practitionerFirstName: user.practitionerFirstName,
            practitionerId: user.practitionerId,
            practitionerLastName: user.practitionerLastName,
          });
        }
      });
    });
  }
  providersList = providerSearch
    ? providersList?.filter(i => {
        let providerName = `${i.practitionerFirstName} ${i.practitionerLastName}`;
        return !!providerName.toLowerCase().includes(providerSearch.toLowerCase());
      })
    : providersList;
  useEffect(() => {
    let selectedUserBusinessFunction = props.selectedUserRole?.businessFunction;
    if (
      selectedUserBusinessFunction &&
      selectedUserBusinessFunction !== "Clinical Care" &&
      orgUsersData?.length === 0 &&
      !props.orgUsers.loading
    ) {
      props.getOrgUsers();
    }
    if (orgUsersData?.length > 0) {
      let provider = orgUsersData[0];
      let savedFilter = getLocalStorage("selected-provider-filter");
      if (savedFilter) {
        provider = savedFilter;
      }
      provider = {
        ...provider,
        practitionerEmail: provider.practitionerEmail,
        practitionerFirstName: provider.practitionerFirstName,
        practitionerId: provider.practitionerId,
        practitionerLastName: provider.practitionerLastName,
      };
      setSelectedProvider(provider);
    }
  }, [orgUsersData]);
  const selectProvider = provider => {
    addLocalStorage("selected-provider-filter", provider);
    setSelectedProvider(provider);
  };
  return (
    <div className="dashboard-widget upcoming-today-widget-container">
      <div className="widget-header">
        <div className="widget-title">Today's Appointment</div>
        {props.selectedUserRole?.businessFunction !== "Clinical Care" && (
          <div className="provider-filter-container">
            <Form.Label className="provider-filter-label">Filter by Provider:</Form.Label>
            <Dropdown className="provider-filter-dropdown">
              <Dropdown.Toggle>
                {!!selectedProvider
                  ? `${selectedProvider?.practitionerFirstName} ${selectedProvider?.practitionerLastName}`
                  : "Select Provider"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Search
                  placeholder={"Search provider"}
                  value={providerSearch}
                  onSearch={val => setProviderSearch(val)}
                  clearSearch={() => setProviderSearch("")}
                  noDebounce={true}
                />
                <Loader loading={props.orgUsers.loading}>
                  {providersList.length > 0 ? (
                    providersList.map(practitioner => {
                      return (
                        <Dropdown.Item
                          as={"button"}
                          onClick={() => selectProvider(practitioner)}
                          key={practitioner.id}
                          className="provider-filter-dropdown-item"
                        >
                          <div className="provider-filter-dropdown-item-top">
                            <MemberAvatar
                              name={`${practitioner?.practitionerFirstName} ${practitioner?.practitionerLastName}`}
                              size={"small"}
                              showFullName={true}
                            />
                            <div className="practitioner-roles">
                              <div className="role">{practitioner.practitionerEmail}</div>
                            </div>
                          </div>
                          <div className="practitioner-roles">
                            <div className="role">{practitioner.behaviorHealthRole}</div>
                          </div>
                        </Dropdown.Item>
                      );
                    })
                  ) : (
                    <Dropdown.Item
                      as={"button"}
                      className="no-items-found"
                    >
                      No Providers Found
                    </Dropdown.Item>
                  )}
                </Loader>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>
      <Loader loading={loading}>
        {upcomingSessions?.length > 0 ? (
          <div className="sessions-list">
            {upcomingSessions.map(session => {
              return (
                <SessionCard
                  session={session}
                  isUpcoming={true}
                  key={session.id}
                  getSessionsByPractitionerRole={props.getSessionsByPractitionerRole}
                />
              );
            })}
          </div>
        ) : (
          <div className="no-sessions-found-container">
            <div className="no-sessions-found">No Sessions Found</div>
          </div>
        )}
      </Loader>
    </div>
  );
}

export default UpcomingSessions;
