import TreatmentPlan from "./TreatmentPlan";
import { connect } from "react-redux";
import { getTreatmentPlan, generateTreatmentPlan } from "@/store/modules/userModule";

const mapStateToProps = state => {
  return {
    treatmentPlan: state.user.treatmentPlan,
    selectedUserRole: state.user.selectedUserRole,
    preferredTimezone: state.user.preferredTimezone,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTreatmentPlan: options => dispatch(getTreatmentPlan(options)),
    generateTreatmentPlan: options => dispatch(generateTreatmentPlan(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentPlan);
