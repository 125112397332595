import UpcomingSessions from "./UpcomingSessions";
import { connect } from "react-redux";
import { getTodaysSessionsByPractitionerRole } from "@/Routes/Appointments/appointmentsModule";
import { getOrgUsers } from "@/Routes/Users/usersModule";

const mapStateToProps = state => {
  return {
    sessions: state.appointments.todaysSessions,
    selectedUserRole: state.user.selectedUserRole,
    preferredTimezone: state.user.preferredTimezone,
    orgUsers: state.orgUsers.users,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTodaysSessionsByPractitionerRole: options =>
      dispatch(getTodaysSessionsByPractitionerRole(options)),
    getOrgUsers: options => dispatch(getOrgUsers(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingSessions);
