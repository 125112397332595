import { get, post, rawPost } from "@/services/api";
import * as Sentry from "@sentry/react";

export const getSessionDetails = options => {
  return async dispatch => {
    let url = `practitioner-role/sessions/find-by-id/${options?.sessionId}`;
    if (options?.sessionCategory === "individual") {
      url = `practitioner-role/individual-session/find-by-id/${options?.sessionId}`;
    }
    let sessionDetails = null;
    dispatch({ type: "TOGGLE_MEETING_SESSIONS_LOADER", show: true });
    try {
      let response = await get(url);
      if (response?.data) {
        sessionDetails = response.data;
      }
    } catch (error) {}
    dispatch({ type: "ADD_MEETING_SESSIONS_DATA", data: sessionDetails });
    dispatch({ type: "TOGGLE_MEETING_SESSIONS_LOADER", show: false });
  };
};

export const getMeetingToken = options => {
  let tokenRetry = options?.tokenRetry || 0;
  return (dispatch, getState) => {
    let state = getState();
    let practitionerRoleId = state.user.selectedUserRole?.id;
    let url = "practitioner-role/meeting/individual-session/auth-token";
    let payload = {
      patientId: options?.patientId,
      practitionerRoleId,
    };
    if (options?.sessionCategory === "group") {
      url = "practitioner-role/meeting/groups/auth-token";
      payload = {
        groupId: options?.groupId,
        practitionerRoleId,
      };
    }
    dispatch({ type: "TOGGLE_MEETING_TOKEN_LOADER", show: true });
    post(url, payload)
      .then(response => {
        if (response?.data) {
          if (options?.sessionCategory === "group") {
            dispatch({
              type: "ADD_MEETING_TOKEN",
              token: response.data,
              id: options?.groupId,
              sessionCategory: "group",
            });
          } else {
            dispatch({
              type: "ADD_MEETING_TOKEN",
              token: response.data,
              id: options?.patientId,
              sessionCategory: "individual",
            });
          }
        }
        dispatch({ type: "TOGGLE_MEETING_TOKEN_LOADER", show: false });
      })
      .catch(error => {
        console.log(error);
        if (tokenRetry < 3) {
          tokenRetry = tokenRetry + 1;
          dispatch(getMeetingToken({ ...options, tokenRetry }));
        } else {
          dispatch({ type: "TOGGLE_MEETING_TOKEN_LOADER", show: false });
        }
        // alert(JSON.stringify(error));
      });
  };
};

export const getMemberNotes = options => {
  return dispatch => {
    let url = `practitioner-role/member-notes/${options?.sessionId}/find`;
    dispatch({ type: "TOGGLE_IN_SESSION_NOTES_LOADER", show: true });
    get(url)
      .then(response => {
        if (response?.data) {
          dispatch({ type: "ADD_IN_SESSION_NOTES", data: response.data });
        }
        dispatch({ type: "TOGGLE_IN_SESSION_NOTES_LOADER", show: false });
      })
      .catch(() => {
        dispatch({ type: "TOGGLE_IN_SESSION_NOTES_LOADER", show: false });
      });
  };
};

export const mapProviderSessionId = options => {
  let tokenRetry = options?.tokenRetry || 0;
  return async dispatch => {
    let url = "practitioner-role/meeting-session/reconcile-provider-session";
    let payload = {
      providerSessionId: options?.providerSessionId,
      providerMeetingId: options?.providerMeetingId || null,
      sessionCategory: options?.sessionCategory === "individual" ? "INDIVIDUAL" : "GROUP",
      modeOfDelivery: options?.modeOfDelivery === "virtual" ? "VIRTUAL" : "IN_PERSON",
      startedAt: new Date().toISOString(),
    };
    let meetingSessionData = null;
    dispatch({ type: "TOGGLE_MEETING_SESSION_LOADER", show: true });
    if (options?.sessionCategory === "individual") {
      payload = {
        ...payload,
        individualSessionId: options?.sessionId,
        patientId: options?.patientId,
      };
    } else {
      payload = {
        ...payload,
        sessionId: options?.sessionId,
        groupId: options?.groupId,
      };
    }
    try {
      let response = await post(url, payload);
      if (response?.data) {
        meetingSessionData = response.data;
      }
    } catch (error) {
      if (tokenRetry < 10) {
        tokenRetry = tokenRetry + 1;
        dispatch(mapProviderSessionId({ ...options, tokenRetry }));
      } else {
        dispatch({ type: "ADD_MEETING_SESSION_DATA", data: meetingSessionData });
        dispatch({ type: "TOGGLE_MEETING_SESSION_LOADER", show: false });
      }
      console.log(error);
    }
  };
};

export const changeMeetingSession = options => {
  return async dispatch => {
    let url = "practitioner-role/meeting-session/update-meeting-session";
    let payload = options?.payload || {};
    dispatch({ type: "ADD_MEETING_SESSION_DATA", data: payload });
    try {
      await post(url, payload);
    } catch (error) {
      console.log(error);
    }
  };
};

const initState = {
  sessionDetails: {
    data: {},
    loading: false,
  },
  meetingToken: {
    data: {},
    loading: false,
  },
  memberNotes: {
    data: [],
    loading: false,
  },
  meetingSession: {
    data: null,
    loading: true,
  },
};

const ACTION_HANDLERS = {
  ["TOGGLE_MEETING_SESSIONS_LOADER"]: (state, action) => {
    return {
      ...state,
      sessionDetails: {
        ...state.sessionDetails,
        loading: action.show,
      },
    };
  },
  ["ADD_MEETING_SESSIONS_DATA"]: (state, action) => {
    return {
      ...state,
      sessionDetails: {
        ...state.sessionDetails,
        data: action.data,
      },
    };
  },
  ["TOGGLE_MEETING_TOKEN_LOADER"]: (state, action) => {
    return {
      ...state,
      meetingToken: {
        ...state.meetingToken,
        loading: action.show,
      },
    };
  },
  ["ADD_MEETING_TOKEN"]: (state, action) => {
    return {
      ...state,
      meetingToken: {
        ...state.meetingToken,
        data: {
          ...state.meetingToken.data,
          [action.id]: {
            token: action.token,
            sessionCategory: action.sessionCategory,
          },
        },
      },
    };
  },
  ["TOGGLE_IN_SESSION_NOTES_LOADER"]: (state, action) => {
    return {
      ...state,
      memberNotes: {
        ...state.memberNotes,
        loading: action.show,
      },
    };
  },
  ["ADD_IN_SESSION_NOTES"]: (state, action) => {
    return {
      ...state,
      memberNotes: {
        ...state.memberNotes,
        data: action.data,
      },
    };
  },
  ["TOGGLE_MEETING_SESSION_LOADER"]: (state, action) => {
    return {
      ...state,
      meetingSession: {
        ...state.meetingSession,
        loading: action.show,
      },
    };
  },
  ["ADD_MEETING_SESSION_DATA"]: (state, action) => {
    return {
      ...state,
      meetingSession: {
        ...state.meetingSession,
        data: action.data,
      },
    };
  },
};

export default function session(state = initState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
