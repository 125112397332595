import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { MemberAvatar, Loader, PrimaryButton, SecondaryButton } from "@/components";
import { Link, useNavigate } from "react-router-dom";
import { getLocalStorage } from "@/services/utils";
import { post } from "@/services/api";
import InPersonIcon from "@/assets/sofa.svg";
import VirtualIcon from "@/assets/video-recorder.svg";
import GroupsIcon from "@/assets/group.svg";
import IntakeIcon from "@/assets/intake.svg";
import "./PatientSessions.scss";
import { SessionStartLink } from "@/Routes/Appointments/components/Sessions/components/SessionCard/SessionCard";

function PatientSessions(props) {
  const { patientId } = props;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const sessionsList = props.sessions.data;
  let completedSessions = sessionsList.filter(i => {
    if (i.sessionCategory === "INDIVIDUAL") {
      return i.patientId === patientId;
    } else {
      let sessionPatients = i.sessionPatientMemberDtos?.map(i => i.patientId);
      if (sessionPatients?.includes(patientId)) {
        return true;
      }
    }
  });
  completedSessions = completedSessions.sort(
    (session1, session2) => new Date(session2.startTime) - new Date(session1.startTime)
  );
  const startInstantSession = async () => {
    let sessionId = await scheduleSession();
    let url = `/session/individual/virtual/${sessionId}/${patientId}`;
    navigate(url);
  };
  const scheduleSession = async () => {
    let sessionId = "";
    let url = "practitioner-role/individual-session/create";
    let patientName = `${props.patientData?.firstName} ${props.patientData?.lastName}`;
    let sessionName = `Telehealth session with ${patientName}`;
    let offsetStartDateTime = new Date().toISOString();
    let payload = {
      patientId,
      practitionerRoleId: props.selectedUserRole?.id,
      sessionName,
      offsetStartDateTime,
      durationInMinutes: 15,
      sessionCategory: "INDIVIDUAL",
      modeOfDelivery: "VIRTUAL",
      appointmentType: "FOLLOW_UP",
    };
    setLoading(true);
    let sessionData = null;
    try {
      let response = await post(url, payload);
      if (response?.data) {
        sessionId = response.data?.id;
        sessionData = response.data;
      }
    } catch (error) {}
    setLoading(false);
    if (sessionId) {
      props.getSessionsByPatientId(patientId);
    }
    return sessionId;
  };
  return (
    <div className="patient-sessions-container">
      <div className="widget-header">
        <div className="widget-title">Patient Sessions</div>
        {props.selectedUserRole?.businessFunction === "Clinical Care" && (
          <SecondaryButton
            className="patient-sessions-start-session"
            onClick={startInstantSession}
          >
            Start New Session
          </SecondaryButton>
        )}
      </div>
      <div className="completed-session-container">
        <Loader loading={loading || props.sessions.loading}>
          {/* <div className="past-sessions-label">Past Sessions</div> */}
          {completedSessions?.length > 0 ? (
            <div className="completed-sessions-list">
              {completedSessions.map(session => {
                return (
                  <PatientSessionCard
                    session={session}
                    key={session.id}
                    preferredTimezone={props.preferredTimezone}
                    selectedUserRole={props.selectedUserRole}
                  />
                );
              })}
            </div>
          ) : (
            <div className="no-sessions-found">No Sessions Found</div>
          )}
        </Loader>
      </div>
    </div>
  );
}

export default PatientSessions;

export const PatientSessionCard = props => {
  const { session } = props;
  let sessionTime = `${moment(session.startTime)
    .tz(props.preferredTimezone?.name || "America/Los_Angeles")
    .format("MMMM DD")}, ${moment(session.startTime)
    .tz(props.preferredTimezone?.name || "America/Los_Angeles")
    .format("h:mm a")} ${props.preferredTimezone?.abbr || "PT"} | ${
    session.durationInMinutes
  } mins`;
  // Shifted to dyte for in person sessions from 9th September 2024
  let sessionDate = new Date(session.startTime);
  let updateDate = new Date("2024-09-09T06:00:00.000+05:30");
  let inPersonOldSession = sessionDate < updateDate;
  return (
    <div className="completed-session-item patient-previous-session-item">
      <div className="session-name">{session.sessionName}</div>
      <div className="session-item-patient-container">
        <div className="session-time">{sessionTime}</div>
      </div>
      <div className="session-item-details-container">
        <div className="session-tags-container">
          <div className="session-type">
            <img
              className="session-type-icon"
              src={session.modeOfDelivery === "VIRTUAL" ? VirtualIcon : InPersonIcon}
            />
            {session.modeOfDelivery === "VIRTUAL" ? "Online" : "In-Office"}
          </div>
          {session.sessionCategory !== "INDIVIDUAL" && (
            <div className="session-type">
              <img
                className="session-type-icon"
                src={GroupsIcon}
              />
              Group
            </div>
          )}
          {session.sessionCategory === "INDIVIDUAL" && session.appointmentType === "INTAKE" && (
            <div className="session-type">
              <img
                className="session-type-icon"
                src={IntakeIcon}
              />
              Intake
            </div>
          )}
        </div>
        {!props.hideDetailsLink && (
          <div className="session-details-links-container">
            {props.selectedUserRole?.businessFunction === "Clinical Care" ? (
              <SessionStartLink session={session} />
            ) : (
              <div></div>
            )}
            <Link
              className="session-details-link"
              to={`/session-details/${
                session.sessionCategory === "INDIVIDUAL" ? "individual" : "group"
              }/${session.modeOfDelivery === "VIRTUAL" ? "virtual" : "in-person"}/${session.id}/${
                session.sessionCategory === "INDIVIDUAL" ? session.patientId : session.groupId
              }${inPersonOldSession ? "?inPersonOldSession=true" : ""}`}
            >
              View Details
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
