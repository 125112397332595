import { get, post } from "@/services/api";
import { addLocalStorage, getLocalStorage } from "@/services/utils";
import Papa from "papaparse";
import { toast } from "react-toastify";
import { marked } from "marked";

const SESSION_TRANSCRIPT_LLM_API_URL = import.meta.env.VITE_SESSION_TRANSCRIPT_LLM_API_URL;
const WEBSOCKET_API_URL = import.meta.env.VITE_IN_SESSION_SOCKET_URL;

export const getSessionDetails = options => {
  return async dispatch => {
    let url = `practitioner-role/sessions/find-by-id/${options?.sessionId}`;
    if (options?.sessionCategory === "individual") {
      url = `practitioner-role/individual-session/find-by-id/${options?.sessionId}`;
    }
    dispatch({ type: "TOGGLE_SESSION_DETAILS_SESSION_LOADER", show: true });
    let sessionDetails = null;
    try {
      let response = await get(url);
      if (response?.data) {
        let data = response.data || null;
        sessionDetails = data;
        dispatch({ type: "ADD_SESSION_DETAILS_SESSION_DATA", data });
      }
    } catch (error) {}
    dispatch({ type: "TOGGLE_SESSION_DETAILS_SESSION_LOADER", show: false });
    return sessionDetails;
  };
};

export const getMemberNotes = options => {
  return async dispatch => {
    let url = `practitioner-role/member-notes/${options?.sessionId}/find`;
    dispatch({ type: "TOGGLE_SESSION_DETAILS_MEMBER_NOTES_LOADER", show: true });
    let memberNotes = {};
    try {
      let response = await get(url);
      if (response?.data) {
        let memberNotesList = [...response.data];
        memberNotes = memberNotesList.find(i => i.userId === options?.patientId) || {};
        // let email = await getMemberEmail({ userId: memberNotes.userId });
        // memberNotes = {
        //   ...memberNotes,
        //   email,
        // };
      }
    } catch (error) {}
    dispatch({ type: "ADD_SESSION_DETAILS_MEMBER_NOTES", data: memberNotes });
    dispatch({ type: "TOGGLE_SESSION_DETAILS_MEMBER_NOTES_LOADER", show: false });
  };
};

export const getMemberEmail = async ({ userId }) => {
  let url = `practitioner-role/meeting-user/${userId}/retrieve-email`;
  let email = "";
  try {
    // let response = await get(url);
    // email = response?.data || "";
    return email;
  } catch {
    return email;
  }
};

export const updateMemberNotes = options => {
  return async (dispatch, getState) => {
    let state = getState();
    let practitionerRoleId = state.user.selectedUserRole?.id;
    let payload = {
      ...options.payload,
      practitionerRoleId,
    };
    let url = "practitioner-role/member-notes/update";
    dispatch({ type: "TOGGLE_SESSION_DETAILS_UPDATE_NOTES_LOADER", show: true });
    try {
      let response = await post(url, payload);
      dispatch({ type: "UPDATE_SESSION_DETAILS_MEMBER_NOTES", data: payload });
      if (options?.successCb) {
        options.successCb();
      }
    } catch (error) {
      if (options?.errorCb) {
        options.errorCb();
      }
    }
    dispatch({ type: "TOGGLE_SESSION_DETAILS_UPDATE_NOTES_LOADER", show: false });
  };
};

export const getProviderSessionIds = options => {
  return async dispatch => {
    let transcriptData = [];
    let csvData = [];
    let providerSessionTranscriptsData = [];
    let speakerMapped = true;
    let url =
      options?.sessionCategory === "individual"
        ? `practitioner-role/meeting-session/individual-session/${options?.sessionId}/list/reconciled-provider-sessions`
        : `practitioner-role/meeting-session/group/${options?.sessionId}/list/reconciled-provider-sessions`;
    if (!options?.fromTasks) {
      dispatch({ type: "TOGGLE_SESSION_DETAILS_TRANSCRIPT_LOADER", show: true });
    }
    try {
      let response = await get(url);
      let sessionProvidersList = response?.data || [];
      sessionProvidersList = sessionProvidersList.sort(
        (a, b) => new Date(a.startedAt) - new Date(b.startedAt)
      );
      let retryTranscript = false;
      if (!!sessionProvidersList?.[sessionProvidersList.length - 1]) {
        let latestProviderSessionDetails = sessionProvidersList[sessionProvidersList.length - 1];
        const startedAt = new Date(latestProviderSessionDetails?.startedAt);
        const currentTime = new Date();
        const startTimePlus60 = new Date(startedAt.getTime() + 2 * 60 * 60000);
        retryTranscript = currentTime < startTimePlus60;
      }
      if (!!options?.fromTasks) {
        retryTranscript = false;
      }
      let promises = [];
      sessionProvidersList.forEach(sessionProvider => {
        let providerSessionTimer = null;
        let promise = getProviderSessionTranscript({
          ...options,
          retryTranscript,
          session: sessionProvider,
          providerSessionTimer,
        });
        promises.push(promise);
      });
      let responses = await Promise.allSettled(promises);
      if (responses?.length > 0) {
        responses.forEach(response => {
          if (response?.value) {
            let providerTranscriptData = response.value.transcriptData || [];
            let providerCsvData = response.value.csvData || [];
            let providerSpeakerMapped = !!response.value.speakerMapped;
            transcriptData = [...transcriptData, ...providerTranscriptData];
            csvData = [...csvData, ...providerCsvData];
            // if (options?.sessionCategory === "individual") {
            //   let actualSpeakers = [...new Set(providerTranscriptData.map(i => i.memberId))];
            //   let mappedSpeakers = [...new Set(providerTranscriptData.map(i => i.memberName))];
            //   console.log("actualSpeakers", actualSpeakers);
            //   console.log("mappedSpeakers", mappedSpeakers);

            //   if (actualSpeakers.length === mappedSpeakers.length) {
            //     providerSpeakerMapped = true;
            //   } else {
            //     providerSpeakerMapped = false;
            //   }
            // }
            speakerMapped = speakerMapped && providerSpeakerMapped;
            providerSessionTranscriptsData.push({
              providerSessionId: response.value.providerSessionId,
              csvData: providerCsvData,
              transcriptData: providerTranscriptData,
              speakerMapped: providerSpeakerMapped,
            });
          }
        });
      }
    } catch (error) {
      console.log(error);
    }

    if (!options?.fromTasks) {
      dispatch({
        type: "ADD_SESSION_DETAILS_TRANSCRIPT_DATA",
        data: transcriptData,
        csvData,
        providerSessionTranscriptsData,
      });
      dispatch({
        type: "ADD_SESSION_DETAILS_TRANSCRIPT_SPEAKER_MAPPING_STATUS",
        flag: !!speakerMapped,
      });
      dispatch({ type: "TOGGLE_SESSION_DETAILS_TRANSCRIPT_LOADER", show: false });
    } else {
      return transcriptData;
    }
  };
};

export const getProviderSessionTranscript = async options => {
  let { session, sessionCategory, triggerTime, providerSessionTimer, retryTranscript } = options;
  let secondsPassed = (new Date() - triggerTime) / 1000;
  if (secondsPassed >= 240) {
    retryTranscript = false;
  }
  if (providerSessionTimer) {
    clearTimeout(providerSessionTimer);
  }
  let providerSessionTranscriptData = null;
  let sessionId =
    sessionCategory === "individual" ? session?.individualSessionId : session?.sessionId;
  let providerSessionId = session?.providerSessionId;
  let url =
    sessionCategory === "individual"
      ? `practitioner-role/meeting-session/individual-session/${sessionId}/provider-session-id/${providerSessionId}/transcript`
      : `practitioner-role/meeting-session/group/${sessionId}/provider-session-id/${providerSessionId}/transcript`;
  if (session.modeOfDelivery === "IN_PERSON") {
    url =
      sessionCategory === "individual"
        ? `practitioner-role/meeting-session/individual-session/${sessionId}/provider-session-id/${providerSessionId}/transcript-from-audio`
        : `practitioner-role/meeting-session/group/${sessionId}/provider-session-id/${providerSessionId}/transcript-from-audio`;
  }
  try {
    let response = await get(url);
    if (response?.data) {
      let transcriptUrl = response.data;
      let pdfPromise = new Promise((resolve, reject) => {
        let resultData = null;
        Papa.parse(transcriptUrl, {
          download: true,
          complete: function (results) {
            let data = results?.data || [];
            let speakerMapped = true;
            let transcriptData = data
              .map(i => {
                if (i[4] === i[3]) {
                  speakerMapped = false;
                }
                return {
                  timestamp: i[0] * 1000,
                  memberId: i[3],
                  memberName: i[4],
                  transcriptText: i[5],
                  memberSessionId: i[2],
                };
              })
              .sort((a, b) => a.timestamp - b.timestamp);
            resultData = {
              transcriptData,
              csvData: data,
              speakerMapped,
              providerSessionId,
              sessionId,
            };
            if (transcriptData?.length > 0) {
              resolve(resultData);
            } else {
              if (!!retryTranscript) {
                providerSessionTimer = setTimeout(async () => {
                  let resultRecurData = await getProviderSessionTranscript(options);
                  resolve(resultRecurData);
                }, 10000);
              } else {
                reject(resultData);
              }
            }
          },
          error: function () {
            if (!!retryTranscript) {
              providerSessionTimer = setTimeout(async () => {
                let resultRecurData = await getProviderSessionTranscript(options);
                resolve(resultRecurData);
              }, 10000);
            } else {
              reject(resultData);
            }
          },
        });
      });
      try {
        providerSessionTranscriptData = await pdfPromise;
      } catch (error) {
        console.log(error, providerSessionId);
      }
    } else {
      if (!!retryTranscript) {
        providerSessionTimer = setTimeout(async () => {
          let resultRecurData = await getProviderSessionTranscript(options);
          providerSessionTranscriptData = resultRecurData;
        }, 10000);
      } else {
        providerSessionTranscriptData = null;
      }
    }
  } catch (error) {
    console.log("error", error, providerSessionId);
    if (!!retryTranscript) {
      providerSessionTimer = setTimeout(async () => {
        let resultRecurData = await getProviderSessionTranscript(options);
        providerSessionTranscriptData = resultRecurData;
      }, 10000);
    } else {
      providerSessionTranscriptData = null;
    }
  }
  return providerSessionTranscriptData;
};

let sessionTimer = null;

export const getSessionTranscript = options => {
  return async dispatch => {
    let triggerTime = options?.triggerTime;
    let secondsPassed = (new Date() - triggerTime) / 1000;
    let retryTranscript = options?.retryTranscript;
    if (secondsPassed >= 120) {
      retryTranscript = false;
    }
    if (sessionTimer) {
      clearTimeout(sessionTimer);
    }
    let sessionId = options?.sessionId;
    let url =
      options?.sessionType === "in-person"
        ? `practitioner-role/meeting-session/in-person/${sessionId}/transcript`
        : options?.sessionCategory === "group"
        ? `practitioner-role/meeting-session/group/${sessionId}/transcript`
        : `practitioner-role/meeting-session/individual-session/${sessionId}/transcript`;
    dispatch({ type: "TOGGLE_SESSION_DETAILS_TRANSCRIPT_LOADER", show: true });
    try {
      let response = await get(url);
      if (response?.data) {
        let transcriptUrl = response.data;
        Papa.parse(transcriptUrl, {
          download: true,
          complete: function (results) {
            let data = results?.data || [];
            let speakerMapped = true;
            let transcriptData = data
              .map(i => {
                if (i[4] === i[3]) {
                  speakerMapped = false;
                }
                return {
                  timestamp: i[0] * 1000,
                  memberId: i[3],
                  memberName: i[4],
                  transcriptText: i[5],
                  memberSessionId: i[2],
                };
              })
              .sort((a, b) => a.timestamp - b.timestamp);
            dispatch({
              type: "ADD_SESSION_DETAILS_TRANSCRIPT_DATA",
              data: transcriptData,
              csvData: data,
            });
            dispatch({
              type: "ADD_SESSION_DETAILS_TRANSCRIPT_SPEAKER_MAPPING_STATUS",
              flag: !!speakerMapped,
            });
            dispatch({ type: "TOGGLE_SESSION_DETAILS_TRANSCRIPT_LOADER", show: false });
          },
          error: function (error) {
            if (!!retryTranscript) {
              sessionTimer = setTimeout(() => {
                dispatch(getSessionTranscript(options));
              }, 10000);
            } else {
              dispatch({
                type: "ADD_SESSION_DETAILS_TRANSCRIPT_DATA",
                data: [],
                csvData: [],
              });
              dispatch({ type: "TOGGLE_SESSION_DETAILS_TRANSCRIPT_LOADER", show: false });
            }
            // dispatch({ type: "TOGGLE_SESSION_DETAILS_TRANSCRIPT_LOADER", show: false });
            // dispatch({ type: "ADD_SESSION_DETAILS_TRANSCRIPT_DATA", data: [] });
          },
        });
      } else {
        if (!!retryTranscript) {
          sessionTimer = setTimeout(() => {
            dispatch(getSessionTranscript(options));
          }, 10000);
        } else {
          dispatch({ type: "TOGGLE_SESSION_DETAILS_TRANSCRIPT_LOADER", show: false });
          dispatch({ type: "ADD_SESSION_DETAILS_TRANSCRIPT_DATA", data: [], csvData: [] });
        }
      }
    } catch (error) {
      dispatch({ type: "TOGGLE_SESSION_DETAILS_TRANSCRIPT_LOADER", show: false });
      dispatch({ type: "ADD_SESSION_DETAILS_TRANSCRIPT_DATA", data: [], csvData: [] });
    }
  };
};

export const getSessionMemberNotes = options => {
  return async dispatch => {
    let { sessionId, patientId } = options;
    let url = `practitioner-role/session-member-notes/session/${sessionId}/find`;
    if (!options.noAction) {
      dispatch({ type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER", show: true });
    }
    let soapNotesData = "";
    let bhPredictionsData = null;
    let notesId = "";
    let soapNotesJson = null;
    let soapNotesJsonData = null;
    try {
      let response = await get(url);
      if (response?.data?.length > 0) {
        notesId = response?.data?.find(
          i => i.patientId === patientId || i.groupId === patientId
        )?.id;
        soapNotesJson = response?.data?.find(
          i => i.patientId === patientId || i.groupId === patientId
        )?.jsonSoapNote;
        if (soapNotesJson) {
          if (!soapNotesJson.hasOwnProperty("groupExtendedNotes")) {
            soapNotesJsonData = {};
            Object.keys(soapNotesJson)?.forEach(section => {
              soapNotesJsonData[
                section !== "chiefCompliantEnhanced" ? section.toLowerCase() : section
              ] = soapNotesJson[section];
            });
            let newData = {};
            Object.keys(soapNotesJsonData)?.forEach(section => {
              if (
                section !== "narrative" &&
                section !== "intake-assessment" &&
                section !== "chiefCompliantEnhanced"
              ) {
                let subSections = Object.keys(soapNotesJsonData[section]);
                subSections?.forEach(subSection => {
                  let subSectionObj = soapNotesJsonData[section][subSection];
                  if (subSectionObj?.type === "single_choice_answer") {
                    let subSectionResult = subSectionObj.result;
                    let valuesIdentified =
                      subSectionObj?.values_identified?.length > 0
                        ? [...subSectionObj.values_identified]
                        : [];
                    let valuesRemaining =
                      subSectionObj?.values_remaining?.length > 0
                        ? [...subSectionObj.values_remaining]
                        : [];
                    if (
                      valuesIdentified?.includes(subSectionResult) ||
                      valuesRemaining?.includes(subSectionResult)
                    ) {
                      newData = {
                        ...newData,
                        [section]: newData[section]
                          ? {
                              ...newData[section],
                              [subSection]: subSectionObj,
                            }
                          : {
                              [subSection]: subSectionObj,
                            },
                      };
                    } else {
                      valuesIdentified = [subSectionResult];
                      newData = {
                        ...newData,
                        [section]: newData[section]
                          ? {
                              ...newData[section],
                              [subSection]: {
                                ...subSectionObj,
                                values_identified: valuesIdentified,
                              },
                            }
                          : {
                              [subSection]: {
                                ...subSectionObj,
                                values_identified: valuesIdentified,
                              },
                            },
                      };
                    }
                  } else if (subSectionObj?.type === "multiple_choice_answers") {
                    let subSectionResult =
                      subSectionObj?.result?.length > 0 ? [...subSectionObj.result] : [];
                    let valuesIdentified =
                      subSectionObj?.values_identified?.length > 0
                        ? [...subSectionObj.values_identified]
                        : [];
                    let valuesRemaining =
                      subSectionObj?.values_remaining?.length > 0
                        ? [...subSectionObj.values_remaining]
                        : [];
                    if (subSectionResult?.length > 0) {
                      newData = {
                        ...newData,
                        [section]: newData[section]
                          ? {
                              ...newData[section],
                              [subSection]: subSectionObj,
                            }
                          : {
                              [subSection]: subSectionObj,
                            },
                      };
                    } else {
                      subSectionResult = valuesIdentified;
                      newData = {
                        ...newData,
                        [section]: newData[section]
                          ? {
                              ...newData[section],
                              [subSection]: {
                                ...subSectionObj,
                                result: subSectionResult,
                              },
                            }
                          : {
                              [subSection]: {
                                ...subSectionObj,
                                result: subSectionResult,
                              },
                            },
                      };
                    }
                  } else {
                    newData = {
                      ...newData,
                      [section]: newData[section]
                        ? {
                            ...newData[section],
                            [subSection]: subSectionObj,
                          }
                        : {
                            [subSection]: subSectionObj,
                          },
                    };
                  }
                });
              } else {
                if (section === "narrative") {
                  newData = {
                    ...newData,
                    narrative: soapNotesJsonData.narrative,
                  };
                } else if (section === "intake-assessment") {
                  newData = {
                    ...newData,
                    ["intake-assessment"]: soapNotesJsonData["intake-assessment"],
                  };
                } else if (section === "chiefCompliantEnhanced") {
                  newData = {
                    ...newData,
                    ["chiefCompliantEnhanced"]: soapNotesJsonData["chiefCompliantEnhanced"],
                  };
                }
              }
            });
            soapNotesJsonData = newData;
          } else {
            soapNotesJsonData = soapNotesJson;
          }
        }
        soapNotesData = response?.data?.find(
          i => i.patientId === patientId || i.groupId === patientId
        )?.soapNote;
        bhPredictionsData = response?.data?.find(
          i => i.patientId === patientId || i.groupId === patientId
        )?.behaviouralHealthPredictions;
      }
    } catch (error) {}
    if (!options.noAction) {
      dispatch({
        type: "ADD_SESSION_DETAILS_SOAP_NOTES_DATA",
        data: soapNotesData,
      });
      dispatch({
        type: "ADD_SESSION_DETAILS_SOAP_NOTES_JSON_DATA",
        data: soapNotesJsonData,
      });
      dispatch({
        type: "ADD_SESSION_DETAILS_SOAP_NOTES_ID",
        notesId,
      });
      dispatch({
        type: "ADD_SESSION_DETAILS_BH_PREDICTIONS_DATA",
        data: bhPredictionsData,
      });
      dispatch({
        type: "ADD_SESSION_DETAILS_BH_PREDICTIONS_ID",
        notesId,
      });
      dispatch({ type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER", show: false });
    }
    return { soapNotesData, soapNotesJsonData, notesId, bhPredictionsData };
  };
};

// export const generateSoapNotes = options => {
//   return async dispatch => {
//     let { sessionId, userId } = options;
//     let url = `${SESSION_TRANSCRIPT_LLM_API_URL}/transcript/generate-soap-note`;
//     if (options?.sessionCategory === "group") {
//       url = `${SESSION_TRANSCRIPT_LLM_API_URL}/transcript/generate-soap-note/group/extended`;
//     }
//     let payload = options?.payload || {};
//     dispatch({ type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER", show: true });
//     if (!options?.regenrate && options.sessionCategory !== "group") {
//       dispatch(generateBhPredictions(options));
//     }
//     try {
//       let response = await post(url, payload);
//       let soapNotesData =
//         options?.sessionCategory === "group"
//           ? response?.data?.groupProgressNote || ""
//           : response?.data || "";
//       if (options?.sessionCategory === "group" && response?.data && options?.sessionId) {
//         let storedGroupNotesData = getLocalStorage("groupNotesData") || {};
//         storedGroupNotesData = {
//           ...storedGroupNotesData,
//           [options.sessionId]: response.data,
//         };
//         addLocalStorage("groupNotesData", storedGroupNotesData);
//       }
//       // let soapNotesData = response?.data || "";
//       if (soapNotesData) {
//         dispatch({
//           type: "ADD_SESSION_DETAILS_SOAP_NOTES_DATA",
//           data: soapNotesData,
//         });
//       }
//       await dispatch(saveSoapNotes({ ...options, soapNote: soapNotesData }));
//       dispatch({ type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER", show: false });
//     } catch (error) {
//       dispatch({ type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER", show: false });
//       if (!payload.transcript) {
//         toast.error("No transcripts found for this user", { position: "bottom-left" });
//       } else {
//         toast.error("Error generating Notes, please try again", { position: "bottom-left" });
//       }
//     }
//   };
// };

export const generateSoapNotes = options => {
  return async dispatch => {
    let url = `${SESSION_TRANSCRIPT_LLM_API_URL}/transcript/generate-soap-note`;
    if (options?.sessionCategory === "group") {
      url = `${SESSION_TRANSCRIPT_LLM_API_URL}/transcript/generate-soap-note/group/extended`;
    }
    let notesTemplate = options?.notesTemplate; //default, assessment;
    if (options?.sessionCategory === "individual" && notesTemplate === "assessment") {
      url = `${SESSION_TRANSCRIPT_LLM_API_URL}/transcript/generate-soap-note/custom/serenity`;
    }
    if (options?.sessionCategory === "individual" && notesTemplate === "intake") {
      url = `${SESSION_TRANSCRIPT_LLM_API_URL}/transcript/generate-intake-note/custom/bps-template`;
    }
    let payload = options?.payload || {};
    dispatch({ type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER", show: true });
    if (!options?.regenrate && options.sessionCategory !== "group") {
      //Calling soap notes generation and prediction generation api together is failing
      // dispatch(generateBhPredictions(options));
    }
    // if (!!options?.regenrate) {
    //   dispatch({
    //     type: "ADD_SESSION_DETAILS_SOAP_NOTES_JSON_DATA",
    //     data: null,
    //   });
    // }
    try {
      let response = await post(url, payload);
      let soapNotesData = options?.soapNote || "";
      let soapNotesJsonData = options?.soapNotesJson || null;
      if (options?.sessionCategory === "group") {
        soapNotesData = response?.data?.groupProgressNote || "";
        if (response?.data) {
          soapNotesJsonData = {
            groupExtendedNotes: response.data,
          };
        }
      } else {
        if (response?.data) {
          if (typeof response.data === "string") {
            soapNotesData = response?.data || "";
            // if (options?.sessionCategory === "individual" && notesTemplate === "intake") {
            //   soapNotesData = marked.parse(soapNotesData);
            // }
          } else {
            if (notesTemplate === "assessment") {
              soapNotesJsonData = soapNotesJsonData
                ? { ...soapNotesJsonData, ...response.data }
                : response.data;
            } else if (notesTemplate === "intake") {
              soapNotesJsonData = soapNotesJsonData
                ? { ...soapNotesJsonData, ["intake-assessment"]: response.data }
                : { ["intake-assessment"]: response.data };
            }
          }
        }
      }
      if (response?.data && options?.sessionId) {
        if (options?.sessionCategory === "group") {
          let storedGroupNotesData = getLocalStorage("groupNotesData") || {};
          storedGroupNotesData = {
            ...storedGroupNotesData,
            [options.sessionId]: response.data,
          };
          addLocalStorage("groupNotesData", storedGroupNotesData);
        } else {
          let storedSoapNotesData = getLocalStorage("soapNotesData") || {};
          storedSoapNotesData = {
            ...storedSoapNotesData,
            [options.sessionId]: response.data,
          };
          addLocalStorage("soapNotesData", storedSoapNotesData);
        }
      }
      if (soapNotesData) {
        dispatch({
          type: "ADD_SESSION_DETAILS_SOAP_NOTES_DATA",
          data: soapNotesData,
        });
      }
      let soapNotesJson = null;
      if (soapNotesJsonData) {
        if (!soapNotesJsonData.hasOwnProperty("groupExtendedNotes")) {
          soapNotesJson = {};
          Object.keys(soapNotesJsonData)?.forEach(section => {
            soapNotesJson[section !== "chiefCompliantEnhanced" ? section.toLowerCase() : section] =
              soapNotesJsonData[section];
          });
          let newData = {};
          Object.keys(soapNotesJson)?.forEach(section => {
            if (
              section !== "narrative" &&
              section !== "intake-assessment" &&
              section !== "chiefCompliantEnhanced"
            ) {
              let subSections = Object.keys(soapNotesJson[section]);
              subSections?.forEach(subSection => {
                let subSectionObj = soapNotesJson[section][subSection];
                if (subSectionObj?.type === "single_choice_answer") {
                  let subSectionResult = subSectionObj.result;
                  let valuesIdentified =
                    subSectionObj?.values_identified?.length > 0
                      ? [...subSectionObj.values_identified]
                      : [];
                  let valuesRemaining =
                    subSectionObj?.values_remaining?.length > 0
                      ? [...subSectionObj.values_remaining]
                      : [];
                  if (
                    valuesIdentified?.includes(subSectionResult) ||
                    valuesRemaining?.includes(subSectionResult)
                  ) {
                    newData = {
                      ...newData,
                      [section]: newData[section]
                        ? {
                            ...newData[section],
                            [subSection]: subSectionObj,
                          }
                        : {
                            [subSection]: subSectionObj,
                          },
                    };
                  } else {
                    valuesIdentified = [subSectionResult];
                    newData = {
                      ...newData,
                      [section]: newData[section]
                        ? {
                            ...newData[section],
                            [subSection]: {
                              ...subSectionObj,
                              values_identified: valuesIdentified,
                            },
                          }
                        : {
                            [subSection]: {
                              ...subSectionObj,
                              values_identified: valuesIdentified,
                            },
                          },
                    };
                  }
                } else if (subSectionObj?.type === "multiple_choice_answers") {
                  let subSectionResult =
                    subSectionObj?.result?.length > 0 ? [...subSectionObj.result] : [];
                  let valuesIdentified =
                    subSectionObj?.values_identified?.length > 0
                      ? [...subSectionObj.values_identified]
                      : [];
                  let valuesRemaining =
                    subSectionObj?.values_remaining?.length > 0
                      ? [...subSectionObj.values_remaining]
                      : [];
                  if (subSectionResult?.length > 0) {
                    newData = {
                      ...newData,
                      [section]: newData[section]
                        ? {
                            ...newData[section],
                            [subSection]: subSectionObj,
                          }
                        : {
                            [subSection]: subSectionObj,
                          },
                    };
                  } else {
                    subSectionResult = valuesIdentified;
                    newData = {
                      ...newData,
                      [section]: newData[section]
                        ? {
                            ...newData[section],
                            [subSection]: {
                              ...subSectionObj,
                              result: subSectionResult,
                            },
                          }
                        : {
                            [subSection]: {
                              ...subSectionObj,
                              result: subSectionResult,
                            },
                          },
                    };
                  }
                } else {
                  newData = {
                    ...newData,
                    [section]: newData[section]
                      ? {
                          ...newData[section],
                          [subSection]: subSectionObj,
                        }
                      : {
                          [subSection]: subSectionObj,
                        },
                  };
                }
              });
            } else {
              if (section === "narrative") {
                newData = {
                  ...newData,
                  narrative: soapNotesJsonData.narrative,
                };
              } else if (section === "intake-assessment") {
                newData = {
                  ...newData,
                  ["intake-assessment"]: soapNotesJsonData["intake-assessment"],
                };
              } else if (section === "chiefCompliantEnhanced") {
                newData = {
                  ...newData,
                  ["chiefCompliantEnhanced"]: soapNotesJsonData["chiefCompliantEnhanced"],
                };
              }
            }
          });
          soapNotesJson = newData;
        } else {
          soapNotesJson = soapNotesJsonData;
        }
        dispatch({
          type: "ADD_SESSION_DETAILS_SOAP_NOTES_JSON_DATA",
          data: soapNotesJson,
        });
      }
      await dispatch(
        saveSoapNotes({ ...options, soapNote: soapNotesData, soapNotesJson: soapNotesJson })
      );
      dispatch({ type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER", show: false });
    } catch (error) {
      dispatch({ type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER", show: false });
      if (!payload.transcript) {
        toast.error("No transcripts found", { position: "bottom-left" });
      } else {
        toast.error("Error generating Notes, please try again", { position: "bottom-left" });
      }
    }
  };
};

export const saveSoapNotes = options => {
  return async (dispatch, getState) => {
    let state = getState();
    let { sessionId, groupId, userId, soapNote, patientId, soapNotesJson, notesId } = options;
    let url = "practitioner-role/session-member-notes/save-soap-notes";
    let practitionerRoleId = state.user.selectedUserRole?.id;
    let payload = {
      id: notesId,
      sessionId,
      groupId,
      userId,
      soapNote,
      jsonSoapNote: soapNotesJson,
      patientId,
      practitionerRoleId,
    };
    dispatch({ type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER", show: true });
    try {
      let response = await post(url, payload);
      dispatch({
        type: "ADD_SESSION_DETAILS_SOAP_NOTES_DATA",
        data: soapNote || "",
      });
      if (response?.id) {
        dispatch({
          type: "ADD_SESSION_DETAILS_SOAP_NOTES_ID",
          notesId: response?.id,
        });
      }
      dispatch({
        type: "ADD_SESSION_DETAILS_SOAP_NOTES_JSON_DATA",
        data: soapNotesJson || null,
      });
      toast.success("Notes Saved", { position: "bottom-left" });
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER",
        show: false,
      });
      fetch(`${WEBSOCKET_API_URL}/send-notes-status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          individualSessionId: sessionId,
          patientId: patientId,
          status: "created",
        }),
      })
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.error(error));
    } catch (error) {
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER",
        show: false,
      });
    }
    // if (options?.sessionCategory === "group") {
    // window.location.href = window.location.href;
    // }
  };
};

export const generateTreatmentPlan = options => {
  return async dispatch => {
    let { sessionId, patientId, session } = options;
    let url = `${SESSION_TRANSCRIPT_LLM_API_URL}/transcript/generate-revised-treatment-plan`;
    let payload = options?.payload || {};
    dispatch({
      type: "TOGGLE_SESSION_DETAILS_TREATMENT_PLAN_LOADER",
      show: true,
    });
    try {
      let response = await post(url, payload);
      if (response?.data) {
        if (typeof response.data === "string") {
          throw new Error("Error generating treatment plan, please try again");
        }
        let savedTreatmentPlans = getLocalStorage("saved-treatment-plans") || {};
        savedTreatmentPlans = {
          ...savedTreatmentPlans,
          [patientId]: {
            data: response.data,
            lastUpdatedOn: session?.startTime,
          },
        };
        addLocalStorage("saved-treatment-plans", savedTreatmentPlans);
        dispatch({
          type: "ADD_SESSION_DETAILS_TREATMENT_PLAN_DATA",
          data: response.data,
        });
        // await dispatch(saveBhPredictions({ ...options, bhPredictions: response.data }));
      }
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_TREATMENT_PLAN_LOADER",
        show: false,
      });
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_TREATMENT_PLAN_LOADER",
        show: false,
      });
      if (!payload.transcript) {
        toast.error("No transcripts found", { position: "bottom-left" });
      } else {
        toast.error("Error generating treatment plan, please try again", {
          position: "bottom-left",
        });
      }
    }
  };
};

export const generateBhPredictions = options => {
  return async dispatch => {
    let { sessionId, userId, groupId } = options;
    let url = `${SESSION_TRANSCRIPT_LLM_API_URL}/transcript/generate-phq-gad-predictions`;
    let payload = options?.payload || {};
    dispatch({
      type: "TOGGLE_SESSION_DETAILS_BH_PREDICTIONS_LOADER",
      show: true,
    });
    try {
      let response = await post(url, payload);
      if (response?.data) {
        if (typeof response.data === "string") {
          throw new Error("Error generating behavioural predictions, please try again");
        }
        dispatch({
          type: "ADD_SESSION_DETAILS_BH_PREDICTIONS_DATA",
          data: response.data,
        });
        await dispatch(saveBhPredictions({ ...options, bhPredictions: response.data }));
      }
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_BH_PREDICTIONS_LOADER",
        show: false,
      });
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_BH_PREDICTIONS_LOADER",
        show: false,
      });
      if (!payload.transcript) {
        toast.error("No transcripts found", { position: "bottom-left" });
      } else {
        toast.error("Error generating behavioural predictions, please try again", {
          position: "bottom-left",
        });
      }
    }
  };
};

export const saveBhPredictions = options => {
  return async (dispatch, getState) => {
    let state = getState();
    let practitionerRoleId = state.user.selectedUserRole?.id;
    let { sessionId, groupId, userId, bhPredictions, patientId, notesId } = options;
    let url = "practitioner-role/session-member-notes/save-behavioural-health-predictions";
    let payload = {
      id: notesId,
      sessionId,
      groupId,
      userId,
      behaviouralHealthPredictions: bhPredictions,
      patientId,
      practitionerRoleId,
    };
    dispatch({
      type: "TOGGLE_SESSION_DETAILS_BH_PREDICTIONS_LOADER",
      show: true,
    });
    try {
      let response = await post(url, payload);
      if (response?.id) {
        dispatch({
          type: "ADD_SESSION_DETAILS_BH_PREDICTIONS_ID",
          notesId: response?.id,
        });
      }
      dispatch({
        type: "ADD_SESSION_DETAILS_BH_PREDICTIONS_DATA",
        data: bhPredictions || null,
      });
      toast.success("Behavioural Predictions Saved", { position: "bottom-left" });
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_BH_PREDICTIONS_LOADER",
        show: false,
      });
    } catch (error) {
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_BH_PREDICTIONS_LOADER",
        show: false,
      });
    }
  };
};

export const generateNarrative = options => {
  return async (dispatch, getState) => {
    let { sessionId, userId, groupId } = options;
    let url = `${SESSION_TRANSCRIPT_LLM_API_URL}/transcript/generate-narrative`;
    let payload = options?.payload || {};
    dispatch({
      type: "TOGGLE_SESSION_DETAILS_NARRATIVE_LOADER",
      show: true,
    });
    try {
      let response = await post(url, payload);
      if (response?.data) {
        // dispatch({
        //   type: "ADD_SESSION_DETAILS_NARRATIVE_DATA",
        //   data: response.data,
        // });
        await dispatch(saveNarrativeData({ ...options, narrativeData: response.data }));
      }
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_NARRATIVE_LOADER",
        show: false,
      });
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_NARRATIVE_LOADER",
        show: false,
      });
      if (!payload.transcript) {
        toast.error("No transcripts found", { position: "bottom-left" });
      } else {
        toast.error("Error generating narrative, please try again", {
          position: "bottom-left",
        });
      }
    }
  };
};

export const saveNarrativeData = options => {
  return async (dispatch, getState) => {
    let state = getState();
    let { sessionId, groupId, userId, soapNote, patientId, soapNotesJson, narrativeData, notesId } =
      options;
    let url = "practitioner-role/session-member-notes/save-soap-notes";
    let practitionerRoleId = state.user.selectedUserRole?.id;
    let payload = {
      id: notesId,
      sessionId,
      groupId,
      userId,
      soapNote,
      jsonSoapNote: soapNotesJson
        ? { ...soapNotesJson, narrative: narrativeData }
        : { narrative: narrativeData },
      patientId,
      practitionerRoleId,
    };
    dispatch({ type: "TOGGLE_SESSION_DETAILS_NARRATIVE_LOADER", show: true });
    try {
      let response = await post(url, payload);
      dispatch({
        type: "ADD_SESSION_DETAILS_NARRATIVE_DATA",
        data: response?.data?.jsonSoapNote?.narrative,
      });
      if (response?.id) {
        dispatch({
          type: "ADD_SESSION_DETAILS_SOAP_NOTES_ID",
          notesId: response?.id,
        });
      }
      dispatch({
        type: "ADD_SESSION_DETAILS_SOAP_NOTES_JSON_DATA",
        data: response?.data?.jsonSoapNote || null,
      });
      toast.success("Narrative Saved", { position: "bottom-left" });
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_NARRATIVE_LOADER",
        show: false,
      });
    } catch (error) {
      dispatch({
        type: "TOGGLE_SESSION_DETAILS_NARRATIVE_LOADER",
        show: false,
      });
    }
  };
};

export const saveSpeakerMapping = options => {
  return async (dispatch, getState) => {
    let {
      csvData,
      transcriptData,
      sessionId,
      groupId,
      providerSessionTranscriptsData,
      sessionCategory,
    } = options;
    dispatch({
      type: "ADD_SESSION_DETAILS_TRANSCRIPT_DATA",
      data: transcriptData,
      csvData: csvData,
      providerSessionTranscriptsData,
    });
    dispatch({
      type: "ADD_SESSION_DETAILS_TRANSCRIPT_SPEAKER_MAPPING_STATUS",
      flag: true,
    });
    providerSessionTranscriptsData?.forEach(async providerSession => {
      if (!providerSession.speakerMapped || sessionCategory === "individual") {
        try {
          let csv = Papa.unparse(providerSession.csvData);
          let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
          let csvFile = new File([blob], "session-transcripts-from-audio");
          let url = `practitioner-role/meeting-session/group/transcript-from-audio/upload?sessionId=${sessionId}&providerSessionId=${providerSession.providerSessionId}`;
          if (sessionCategory === "individual") {
            url = `practitioner-role/meeting-session/individual-session/transcript-from-audio/upload?individualSessionId=${sessionId}&providerSessionId=${providerSession.providerSessionId}`;
          }
          try {
            let payload = new FormData();
            payload.append("file", csvFile);
            await post(url, payload, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          } catch (error) {
            console.log(error);
          }
        } catch (error) {
          console.log(error);
        }
      }
    });
  };
};

const initState = {
  session: {
    data: null,
    loading: false,
  },
  memberNotes: {
    data: {},
    loading: false,
    updateLoading: false,
  },
  soapNotes: {
    data: "",
    notesId: "",
    loading: false,
    soapNotesJson: null,
  },
  narrative: {
    data: "",
    loading: false,
  },
  bhPredictions: {
    data: null,
    notesId: "",
    loading: false,
  },
  sessionTranscript: {
    data: [],
    csvData: [],
    speakerMapped: false,
    providerSessionTranscriptsData: [],
    loading: false,
  },
  treatmentPlan: {
    data: null,
    loading: false,
  },
};

const ACTION_HANDLERS = {
  ["TOGGLE_SESSION_DETAILS_SESSION_LOADER"]: (state, action) => {
    return {
      ...state,
      session: {
        ...state.session,
        loading: action.show,
      },
    };
  },
  ["ADD_SESSION_DETAILS_SESSION_DATA"]: (state, action) => {
    return {
      ...state,
      session: {
        ...state.session,
        data: action.data,
      },
    };
  },
  ["TOGGLE_SESSION_DETAILS_MEMBER_NOTES_LOADER"]: (state, action) => {
    return {
      ...state,
      memberNotes: {
        ...state.memberNotes,
        loading: action.show,
      },
    };
  },
  ["ADD_SESSION_DETAILS_MEMBER_NOTES"]: (state, action) => {
    return {
      ...state,
      memberNotes: {
        ...state.memberNotes,
        data: action.data,
      },
    };
  },
  ["TOGGLE_SESSION_DETAILS_UPDATE_NOTES_LOADER"]: (state, action) => {
    return {
      ...state,
      memberNotes: {
        ...state.memberNotes,
        updateLoading: action.show,
      },
    };
  },
  ["UPDATE_SESSION_DETAILS_MEMBER_NOTES"]: (state, action) => {
    return {
      ...state,
      memberNotes: {
        ...state.memberNotes,
        data: action.data,
      },
    };
  },
  ["TOGGLE_SESSION_DETAILS_TRANSCRIPT_LOADER"]: (state, action) => {
    return {
      ...state,
      sessionTranscript: {
        ...state.sessionTranscript,
        loading: action.show,
      },
    };
  },
  ["ADD_SESSION_DETAILS_TRANSCRIPT_DATA"]: (state, action) => {
    return {
      ...state,
      sessionTranscript: {
        ...state.sessionTranscript,
        data: action.data,
        csvData: action.csvData,
        providerSessionTranscriptsData: action.providerSessionTranscriptsData || [],
      },
    };
  },
  ["ADD_SESSION_DETAILS_TRANSCRIPT_SPEAKER_MAPPING_STATUS"]: (state, action) => {
    return {
      ...state,
      sessionTranscript: {
        ...state.sessionTranscript,
        speakerMapped: action.flag,
      },
    };
  },
  ["TOGGLE_SESSION_DETAILS_SOAP_NOTES_LOADER"]: (state, action) => {
    return {
      ...state,
      soapNotes: {
        ...state.soapNotes,
        loading: action.show,
      },
    };
  },
  ["ADD_SESSION_DETAILS_SOAP_NOTES_DATA"]: (state, action) => {
    return {
      ...state,
      soapNotes: {
        ...state.soapNotes,
        data: action.data,
      },
    };
  },
  ["ADD_SESSION_DETAILS_SOAP_NOTES_ID"]: (state, action) => {
    return {
      ...state,
      soapNotes: {
        ...state.soapNotes,
        notesId: action.notesId,
      },
    };
  },
  ["ADD_SESSION_DETAILS_SOAP_NOTES_JSON_DATA"]: (state, action) => {
    return {
      ...state,
      soapNotes: {
        ...state.soapNotes,
        soapNotesJson: action.data,
      },
    };
  },
  ["TOGGLE_SESSION_DETAILS_BH_PREDICTIONS_LOADER"]: (state, action) => {
    return {
      ...state,
      bhPredictions: {
        ...state.bhPredictions,
        loading: action.show,
      },
    };
  },
  ["ADD_SESSION_DETAILS_BH_PREDICTIONS_DATA"]: (state, action) => {
    return {
      ...state,
      bhPredictions: {
        ...state.bhPredictions,
        data: action.data,
      },
    };
  },
  ["ADD_SESSION_DETAILS_BH_PREDICTIONS_ID"]: (state, action) => {
    return {
      ...state,
      bhPredictions: {
        ...state.bhPredictions,
        notesId: action.notesId,
      },
    };
  },
  ["TOGGLE_SESSION_DETAILS_TREATMENT_PLAN_LOADER"]: (state, action) => {
    return {
      ...state,
      treatmentPlan: {
        ...state.treatmentPlan,
        loading: action.show,
      },
    };
  },
  ["ADD_SESSION_DETAILS_TREATMENT_PLAN_DATA"]: (state, action) => {
    return {
      ...state,
      treatmentPlan: {
        ...state.treatmentPlan,
        data: action.data,
      },
    };
  },
  ["TOGGLE_SESSION_DETAILS_NARRATIVE_LOADER"]: (state, action) => {
    return {
      ...state,
      narrative: {
        ...state.narrative,
        loading: action.show,
      },
    };
  },
  ["ADD_SESSION_DETAILS_NARRATIVE_DATA"]: (state, action) => {
    return {
      ...state,
      narrative: {
        ...state.narrative,
        data: action.data,
      },
    };
  },
};

export default function sessionDetails(state = initState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
