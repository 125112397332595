import SessionAppointments from "./SessionAppointments";
import { connect } from "react-redux";
import { getTodaysSessionsByPractitionerRole } from "@/Routes/Appointments/appointmentsModule";

const mapStateToProps = state => {
  return {
    sessions: state.appointments.todaysSessions,
    selectedUserRole: state.user.selectedUserRole,
    preferredTimezone: state.user.preferredTimezone,
    sessionNotesGenerated: state.appointments?.sessions?.sessionNotesGenerated || [],
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTodaysSessionsByPractitionerRole: options =>
      dispatch(getTodaysSessionsByPractitionerRole(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionAppointments);
